import { clone, trim } from 'ramda';

export default class ButtonPopupEditorController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $element,
    GroupService,
    StoreService,
    BotService,
    UserService,
    BlockService,
    ButtonService,
    CurrencyService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$element = $element;
    this.BotService = BotService;
    this.BlockService = BlockService;
    this.UserService = UserService;
    this.listeners = [];
    this.scrollRcol = true;
    this.ButtonService = ButtonService;
    this.CurrencyService = CurrencyService;
    this.hiddenTabs = {
      blocks: true,
      url: true,
      phone: true,
      submenu: true,
      share: true,
      buy: true,
    };

    this.targetTypes = this.ButtonService.targetTypes;

    this.validatePatterns = this.ButtonService.validatePatterns;

    this.webViewSizes = this.ButtonService.webViewSizes;

    this.blocksTitles = [];
    this.doneFocused = true;

    this.$maxDiscount = 30;

    this.listenerItems = [
      {
        name: '$updateBlocksInSuggest',
        function: () => this.testError(),
        oneTime: false,
      },
      {
        name: '$suggestBlured',
        function: () => {
          if (
            this.tmpItem[this.blocksBox] &&
            this.tmpItem[this.blocksBox].length > 0
          ) {
            this.setTitleFocus();
          }
        },
      },
    ];

    const decideToClose = (event) => {
      const isInDOM = document.contains(event.target);
      const isOutside = !this.$element[0].children[0].contains(event.target);
      if (isInDOM && isOutside && !this.wizardOpen) {
        this.$scope.$evalAsync(() => {
          this.donePopup(null, true);
        });
      }
    };

    setTimeout(
      () => document.addEventListener('mousedown', decideToClose),
      300,
    );
    this.listeners.push(() =>
      document.removeEventListener('mousedown', decideToClose),
    );
  }

  $onInit() {
    const tL = parseInt(this.titleLimit, 10);
    if (tL > 0) {
      this.$maxDiscount = tL;
    }

    setTimeout(() => {
      this.doneButton = this.$element[0].querySelector('.orange-rounded');
    }, 0);

    this.initListeners();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    this.item = null;
    this.iScope = null;
  }

  initListeners() {
    this.listenerItems.forEach((item) => {
      const tmpListener = item.oneTime
        ? this.$scope.$on(item.name, () => {
            tmpListener();

            item.function();
          })
        : this.$scope.$on(item.name, item.function);

      this.listeners.push(tmpListener);
    });

    this.initRootScopeListeners();
  }

  initRootScopeListeners() {
    this.listeners.push(
      this.$rootScope.$on('$set-payment-status-on', (e, type) => {
        switch (type) {
          case 'bot':
            if (this.bot) {
              this.bot.status.payments_status = 'enabled';
            }
            this.botStatusPaymentEnabled = true;
            this.UserService.nativePaymentsForCurrentBot(false, this.user);
            break;

          case 'native':
            this.user.payments_access = true;
            this.UserService.nativePaymentsForCurrentBot(true, this.user);
            break;

          case 'stripe':
            this.bot.payments_stripe = true;
            this.UserService.nativePaymentsForCurrentBot(false, this.user);
            break;

          default:
        }

        this.paymentsAccess =
          this.user.payments_access || this.bot.payments_stripe;
      }),
    );

    this.listeners.push(
      this.$rootScope.$on('$closeOther', () => {
        //  this.close();
        this.donePopup();
      }),
    );

    this.listeners.push(
      this.$rootScope.$on('$onButtonPopupEditor', (event, data) => {
        if (data) {
          this.open(data);
        }
      }),
    );

    this.listeners.push(
      this.$rootScope.$on('card-sort-stop', (event, data) => {
        this.dataInit();
      }),
    );
  }

  open(data) {
    if (this.item && this.item !== data.item) {
      // this.close();
      this.donePopup();
      this.setTitleFocus();
      this.$timeout(() => {
        this.open(data);
      }, 130);
    } else {
      this.item = data.item;
      this.iScope = data.scope;
      this.parent_elem = data.parent_elem;
      this.hide_tabs = data.hide_tabs;
      this.level = data.level;
      this.plugin_type = data.plugin_type;

      this.previousScrollTop = 0;

      this.dataInit();
      this.hideTabs();

      if (data.parent_elem) {
        this.rCol = window.angular.element(document.querySelector('.rcol'));

        const posPopUp = () => {
          const rect = data.element[0]
            .querySelector(data.parent_elem)
            .getBoundingClientRect();
          this.$element[0].children[0].style.top = `${rect.top +
            this.rCol[0].scrollTop -
            85}px`;
          this.$element[0].children[0].style.left = `${rect.left +
            this.rCol[0].scrollLeft -
            180}px`;
        };

        posPopUp();
      } else {
        const parentRect = this.$element.parent()[0].getBoundingClientRect();
        const rect = data.element[0].getBoundingClientRect();
        this.$element[0].children[0].style.top = `${rect.top -
          parentRect.top -
          16}px`;
        this.$element[0].children[0].style.left = `${rect.left -
          parentRect.left +
          180}px`;
      }

      this.$element[0].children[0].classList.add('open');

      this.beenChange = false;
      this.changed = false;
      this.setTitleFocus();
      this.$closeOtherEventSended = true;
      this.titleFocused = true;
      this.$scope.$broadcast('$scrollToStart');
      //   this.$rootScope.$broadcast('$closeOther');
    }
  }

  hideTabs() {
    if (this.hide_tabs) {
      this.hide_tabs.forEach((item) => {
        this.hiddenTabs[item] = false;
      });
    }
  }

  close(event) {
    if (this.iScope) {
      this.iScope.$emit('$closePopup');
      this.iScope.$broadcast('$validateListItem');
    }

    if (this.rCol) {
      this.rCol.off('scroll');
      this.rCol = null;
    }

    this.scrollRcol = false;
    this.item = null;
    this.iScope = null;
    this.targetType = null;
    this.tmpItem = null;

    this.totalCost = null;

    this.parent_elem = null;
    this.hide_tabs = null;

    this.$element[0].children[0].classList.remove('open');
    if (this.hide_tabs) {
      this.hide_tabs.forEach((item) => {
        this.hiddenTabs[item] = true;
      });
    }
  }

  onTitleChange = (title) => {
    this.tmpItem.title = title;
  };

  onKeyDownDone($event) {
    if ($event.keyCode === 13) {
      $event.preventDefault();

      if (this.testError().length === 0 && this.doneFocused) {
        this.donePopup($event);
      }
    }
  }

  onBlurTitle() {
    this.$timeout(() => {
      this.beenChange = true;
      //    this.$scope.$broadcast('$setFocusOutsideStatus');
    }, 200);
  }

  dataInit(data) {
    if (data) {
      this.tmpItem = data;
    } else {
      this.tmpItem = clone(this.item);
    }

    if (!this.tmpItem) {
      return;
    }

    if (this.tmpItem.title) {
      this.tmpItem.title = this.tmpItem.title.trim();
      this.tmpTilte = this.tmpItem.title;
      this.$discount = this.$maxDiscount - this.tmpTilte.length;
    } else {
      this.$discount = this.$maxDiscount;
    }

    this.setTargetType(
      this.ButtonService.typeDetect(this.tmpItem, this.blocksBox),
    );

    this.testError();

    this.updateBotPaymentsStatus(false, true);
  }

  onDonePopup = () => {
    this.donePopup();
  };

  donePopup($event, close) {
    if (this.targetType === 'url' && this.tmpItem.url !== 'undefined') {
      this.tmpItem.url = trim(this.tmpItem.url);
    }

    if (this.testError().length < 2) {
      this.save();
      if (this.hide_tabs) {
        this.hide_tabs.forEach((item) => {
          this.hiddenTabs[item] = true;
        });
      }
    }
    if (close) {
      this.close();
    }
  }

  setTargetType(targetType, sendEvent) {
    this.targetType = targetType;

    this.$timeout(() => {
      this.titleObj = this.$element[0].children[0].querySelector(
        ".title[contenteditable='true']",
      );
      this.testError();
    }, 0);

    this.ButtonService.setTargetType(
      targetType,
      sendEvent,
      this.$element[0].children[0],
      this.$scope,
      this.tmpItem,
      this.blocksBox,
    );
  }

  setTargetTypeWrapper = (targetType) => {
    if (targetType === 'blocks') {
      this.setTargetType(targetType, true);
    } else {
      this.setTargetType(targetType);
    }
  };

  setTitleFocus() {
    this.ButtonService.setTitleFocus(this.$element[0].children[0]);
  }

  testError() {
    this.error = this.ButtonService.testError(
      this.tmpItem,
      this.blocksBox,
      this.titleObj,
      this.targetType,
    );
    return this.error;
  }

  updateDiscount($event) {
    this.$discount = this.$maxDiscount - $event.target.innerText.length;

    if (this.$discount < 0 || typeof this.$discount === 'undefined') {
      $event.target.innerText = $event.target.innerText
        .trim()
        .substring(0, this.$maxDiscount);
      this.$discount = 0;
      setTimeout(() => {
        window.angular.element($event.target).trigger('input');
      }, 1);
    }
  }

  onTitleKeyDown($event) {
    const titleKeyDownResult = this.ButtonService.onTitleKeyDown(
      $event,
      this.$element[0].children[0],
      this.$scope,
      this.targetType,
      this.$maxDiscount,
      this.$discount,
      this.tmpItem,
      this.blocksBox,
      this.titleObj,
    );

    if (titleKeyDownResult === 'donePopup') {
      this.donePopup();
    }
  }

  onTutorialShow = (status) => {
    this.wizardOpen = status;
  };

  onTitlePaste($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.tmpItem.title = $event.target.innerText.substr(0, this.$maxDiscount);
  }

  onUrlTabChange = (newConfig) => {
    if (newConfig.attributeUpdates !== undefined) {
      this.tmpItem.attribute_update_list = newConfig.attributeUpdates;
    }
    if (newConfig.blockId !== undefined) {
      this.tmpItem[this.blocksBox] = newConfig.blockId;
    }
    if (newConfig.url !== undefined) {
      this.tmpItem.url = newConfig.url;
    }
    if (newConfig.webviewHeightRatio !== undefined) {
      this.tmpItem.webview_height_ratio = newConfig.webviewHeightRatio;
    }
  };

  onBlockTabChange = (newConfig) => {
    if (newConfig.attributeUpdates !== undefined) {
      this.tmpItem.attribute_update_list = newConfig.attributeUpdates;
    }
    if (newConfig.blockId !== undefined) {
      this.tmpItem[this.blocksBox] = newConfig.blockId;
    }
  };

  onPhoneCallTabChange = (newConfig) => {
    if (newConfig.phone_number !== undefined) {
      this.tmpItem.phone_number = newConfig.phone_number;
    }
  };

  onBlocksTitlesChange = (titles) => {
    this.blocksTitles = titles;
  };

  save() {
    if (this.tmpItem && this.testError().length < 2) {
      this.ButtonService.save(
        this.tmpItem,
        this.targetType,
        this.titleObj,
        this.item,
      );

      if (
        (!this.item.items && this.targetType === this.targetTypes.submenu) ||
        (this.item.items && this.targetType !== this.targetTypes.submenu)
      ) {
        this.item.thothSubmenu = true;
      }

      Object.assign(this.item, clone(this.tmpItem));

      this.$scope.$emit('$validateCard');
      this.iScope.$emit('$saveMenu');
      this.iScope.$broadcast('$validateListItem');
    }
  }

  onKeyDownActionInput($event) {
    if ($event.keyCode === 13) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      if (this.testError().length < 2) {
        this.donePopup($event);
      }
    }
  }

  updateBotPaymentsStatus(paymentEnabled, useCache) {
    if (!paymentEnabled) {
      this.BotService.cachedList().then((bots) => {
        this.bot = bots.find(
          (item) => item.id === this.$rootScope.stateParams.botId,
        );
        if (this.bot) {
          this.botStatusConnected = Boolean(this.bot.status.page);
          this.botStatusPaymentEnabled =
            this.botStatusConnected &&
            this.bot.status.payments_status === 'enabled';

          if (!this.paymentsAccess) {
            this.paymentsAccess = this.bot.payments_stripe;
          }
        }
      });

      this.UserService.show(!useCache).then((res) => {
        this.user = res;
        if (!this.paymentsAccess) {
          this.paymentsAccess = this.user.payments_access;
        }
      });
    }
  }

  getWarringTTText() {
    return this.ButtonService.getWarringTTText(
      this.botStatusConnected,
      this.botStatusPaymentEnabled,
    );
  }

  onBlockCreated = () => {
    if (this.saveOnCreateBlock === 'true') {
      this.save();
    }
  };
}
