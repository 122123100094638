export default class ArtSwitcherController {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  onClick() {
    if (!this.disabled) {
      this.anim = true;
      this.ngModel = !this.ngModel;
      this.$timeout(() => {
        this.anim = false;
      }, 110);
    }
  }
}
