import template from './art-checkbox.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$artCheckbox',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    ngModel: '=',
    inArray: '=',
    name: '@',
  },
});
