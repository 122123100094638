import template from './button-popup-editor.html';
import controller from './controller';

export default {
  template: () => template,
  controller,
  controllerAs: 'vm',
  bindings: {
    blocksBox: '@',
    titleLimit: '@',
    saveOnCreateBlock: '@',
  },
};
