export default ($http, $rootScope, StoreService, md5) => {
  'ngInject';

  function mapItem(item) {
    item.broadcast_start_timestamp *= 1000;
    return item;
  }

  return {
    schedules() {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/scheduled_entries`,
        method: 'get',
      }).then((res) => res.data.result.map(mapItem));
    },

    updateSchedule(entryId, data, oldEntryType) {
      if (data.broadcast_start_timestamp) {
        data.broadcast_start_timestamp =
          (data.broadcast_start_timestamp / 1000) | 0; // eslint-disable-line no-bitwise
      }
      return $http({
        url: `${StoreService.getApiUrl()}/scheduled_entries/${entryId}`,
        data,
        method: 'post',
        cacheOptions: {
          skipPreCache: true,
        },
      }).then((res) => res.data.result);
    },

    show(id) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/broadcasts/${id}`,
      }).then((res) => mapItem(res.data.result));
    },

    showSchedule(id) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/scheduled_entries/${id}`,
      }).then((res) => mapItem(res.data.result));
    },

    showSequence(id) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/sequences/${id}`,
      }).then((res) => res.data.result);
    },

    removeSchedule(id) {
      return $http({
        url: `${StoreService.getApiUrl()}/scheduled_entries/${id}/delete`,
        method: 'post',
      }).then((res) => res.data);
    },

    removeSequence(id) {
      return $http({
        url: `${StoreService.getApiUrl()}/sequences/${id}/delete`,
        method: 'post',
      }).then((res) => res.data);
    },

    updateRecurrent(id, data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/recurrent_broadcasts/${id}`,
      }).then((res) => res.data.result);
    },

    updateSequence(id, data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/sequences/${id}`,
      }).then((res) => res.data.result);
    },

    listSequence() {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/sequences`,
        method: 'get',
      }).then((res) => res.data.result);
    },

    listAutopost() {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/autoposting`,
        method: 'get',
      }).then((res) => res.data.result.map(mapItem));
    },

    isBroadcastState() {
      return $rootScope.stateParams.tab === 'broadcast';
    },
  };
};
