import { path } from 'ramda';
import { sendEvent } from '../../../utils/Analytics';

export default class ModalController {
  constructor(
    $scope,
    $uibModalInstance,
    $rootScope,
    ruleData,
    $timeout,
    GrowService,
    ruleItemPosition,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$modal = $uibModalInstance;
    this.$rootScope = $rootScope;
    this.GrowService = GrowService;
    this.discount = 300;
    this.ruleData = ruleData;
    this.ruleItemPosition = ruleItemPosition;
  }

  $onInit() {
    this.currentBotID = undefined;
    this.formModel = {};
    this.formState = {};
    this._assign();
    this.updateDiscount();
  }

  _assign() {
    this.currentBotID = this.$rootScope.stateParams.botId;
    this.formState = {
      showPostUrlField: false,
      showPhrasesField: false,
      errors: null,
    };
    this.formModel = this.formModelInit();
  }

  setFocusOnMatchingInput() {
    this.$timeout(() => {
      this.$scope.$broadcast('$setFocusLines');
      this.$scope.$broadcast('$setFocusOutsideStatus', false);
    });
  }

  submitSetupRuleForm(isValid) {
    if (isValid) {
      this.saveRule(this.formModel, this.currentBotID, this.ruleItemPosition);
    }
  }

  closeSetupRuleForm() {
    this.$modal.close(false);
  }

  updateDiscount(e) {
    this.leftSymbols = this.discount;
    if (this.formModel.response) {
      this.leftSymbols = this.discount - this.formModel.response.length;
    }
  }

  formModelInit() {
    if (this.ruleData) {
      this.formModel = this.ruleData;
      if (this.formModel.post_url) {
        this.formState.showPostUrlField = true;
      }
      if (this.formModel.phrases && this.formModel.phrases.length) {
        this.formState.showPhrasesField = true;
      }
      return this.formModel;
    }
    return {};
  }

  async saveRule(data, botId, position) {
    const cleanedData = this.dataCleaning(data);
    sendEvent({
      category: 'acquire users from comments',
      action: 'submit rule',
      label: `${cleanedData.phrases ? 'matching comments' : 'all comments'} / ${
        cleanedData.post_url ? 'specific post' : 'any post'
      }`,
      propertyBag: {
        'track comments under': cleanedData.phrases
          ? 'matching comments'
          : 'all comments',
        'reply to post comments': cleanedData.post_url
          ? 'specific post'
          : 'any post',
      },
    });
    try {
      await this.GrowService.save(botId, cleanedData, position);
      this.$modal.close();
    } catch (e) {
      this.formState.errors = path(
        ['graphQLErrors', 0, 'extensions', 'exception', 'errors'],
        e,
      );
    }
  }

  responseValidationAfterSendingForm(submitResponse) {
    if (!submitResponse.success) {
      return submitResponse.errors;
    }
    this.$modal.close(submitResponse.result);

    return false;
  }

  dataCleaning(data) {
    if (!this.formState.showPostUrlField) {
      data.post_url = null;
    }
    if (!this.formState.showPhrasesField) {
      data.phrases = null;
    }
    return data;
  }
}
