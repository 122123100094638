export default class AutoSizeController {
  constructor($scope, $element, $timeout) {
    'ngInject';

    this.$element = $element;

    const watcher = $scope.$watch('bot.title', () => {
      const elemClientHeight = this.$element[0].clientHeight;
      const elemScrollHeight = this.$element[0].scrollHeight;
      const computedHeight = `${elemScrollHeight + 2}px`;
      if (elemClientHeight < elemScrollHeight) {
        this.$element.css('height', computedHeight);
      }
    });

    $element.on('keypress input', () => this.resize());

    const listener = $scope.$on('$destroy', () => {
      listener();
      watcher();
      $element.off('keypress input');
    });

    $timeout(() => this.resize()); // wait for DOM ready in next tic
  }

  resize() {
    const node = this.$element[0];
    const offset = node.offsetHeight - node.clientHeight;
    this.$element
      .css('overflow', 'hidden')
      .css('height', 'auto')
      .css('height', node.scrollHeight + offset);
  }
}
