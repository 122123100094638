import { clone } from 'ramda';
import { log } from 'cf-common/src/logger';
import { getAdminFeatures } from '../../../utils/Data/Admin';

export default class ModalController {
  constructor(
    $uibModalInstance,
    $scope,
    $timeout,
    $rootScope,
    $cookies,
    content,
    buttons,
    BotService,
    UserService,
    PageService,
    StoreService,
  ) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$cookies = $cookies;
    this.$scope = $scope;
    this.content = content;
    this.buttons = buttons;
    this.BotService = BotService;
    this.UserService = UserService;
    this.PageService = PageService;
    this.StoreService = StoreService;

    this.validatePatterns = {
      url: new RegExp(
        '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]\\S+)$',
        'i',
      ),
      email: new RegExp(
        "^[-a-z0-9~!$%^&*_=+}{'?]+(\\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\\.[-a-z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?$",
        'i',
      ),
    };

    this.BotService.cachedList().then((bots) => {
      this.bot = bots.find(
        (lBot) => lBot.id === this.$rootScope.stateParams.botId,
      );
      //   this.fbPageSettings = `https://www.facebook.com/${bot.status.page}/settings/?tab=payments`;
      this.fbPageSettings = `https://www.facebook.com/${this.bot.status.page}/settings/`;
    });

    this.showLoader = true;

    this.UserService.show().then((res) => {
      this.paymentsAccess = res.payments_access;

      this.paymentPropsDef = {
        merchant_name: res.name,
        merchant_emails_str: res.email,
        terms_of_service_url: '',
      };

      this.currentState = this.states.step0;
      this.showLoader = false;
    });

    this.states = {
      step0: 'step-0',
      step1: 'step-1',
      whoops: 'whoops',
      step2: 'step-2',
      step3: 'step-3',
    };

    this.$modal.result.then(() => {
      this.stopWatch();
    });

    this.loadAdminFeatures();
  }

  $onInit() {
    this.payment_props = {
      merchant_name: '',
      merchant_emails_str: '',
      terms_of_service_url: '',
    };

    this.getPaymentsFields();

    this.$scope.$on('$destroy', () => {
      this.stopWatch();
    });
  }

  loadAdminFeatures = async () => {
    this.isPaymentsNativeEnabled = !!(await getAdminFeatures())
      .payments_native_enabled;
    this.$scope.$evalAsync();
  };

  checkStatus() {
    this.showLoader = true;
    let watchShow = 1000;

    this.$timeout(() => {
      watchShow = 0;
    }, 700);

    this.BotService.cachedList(true).then((bots) => {
      this.$timeout(() => {
        this.showLoader = false;
        this.currentState = this.states.step2;
        this.getPaymentsFields();
      }, watchShow);
    });
  }

  getPaymentsFields() {
    this.BotService.getPayments(this.$rootScope.stateParams.botId).then(
      (res) => {
        if (res.result) {
          if (res.result.merchant_emails) {
            this.payment_props.merchant_emails_str = res.result.merchant_emails
              ? res.result.merchant_emails.join(', ')
              : '';
          } else {
            this.payment_props.merchant_emails_str =
              this.paymentPropsDef.merchant_emails_str;
          }
          if (res.result.merchant_name) {
            this.payment_props.merchant_name = res.result.merchant_name;
          } else {
            this.payment_props.merchant_name =
              this.paymentPropsDef.merchant_name;
          }
          if (res.result.terms_of_service_url) {
            this.payment_props.terms_of_service_url =
              res.result.terms_of_service_url;
          } else {
            this.payment_props.terms_of_service_url =
              this.paymentPropsDef.terms_of_service_url;
          }
        } else {
          this.payment_props = this.paymentPropsDef;
        }

        this.$timeout(() => {
          const cfInput = document.querySelector('.wizard-content .cf-input');
          if (cfInput) {
            cfInput.focus();
          }
        });
        this.validate();
      },
    );
  }

  setPaymentsFields() {
    const pProps = clone(this.payment_props);
    pProps.merchant_emails = pProps.merchant_emails_str
      .split(/\s*,\s*/)
      .filter(Boolean);
    delete pProps.merchant_emails_str;

    return this.BotService.setPayments(
      this.$rootScope.stateParams.botId,
      pProps,
    ).then(() => {
      this.$rootScope.$emit('$set-payment-status-on', 'bot');
    });
  }

  validate() {
    this.errors = [];

    this.payment_props.merchant_emails_str =
      this.payment_props.merchant_emails_str.trim();
    this.payment_props.terms_of_service_url =
      this.payment_props.terms_of_service_url.trim();

    if (
      this.payment_props.merchant_emails_str.length === 0 ||
      !this.payment_props.merchant_emails_str
        .replace(/(^[ ,]+)|([ ,]+$)/, '')
        .split(',')
        .every((email) => this.validatePatterns.email.test(email.trim()))
    ) {
      this.errors.push('email');
    }

    if (
      this.payment_props.terms_of_service_url.length === 0 ||
      !this.validatePatterns.url.test(this.payment_props.terms_of_service_url)
    ) {
      this.errors.push('url');
    }

    return this.errors.length === 0;
  }

  setDefUrl() {
    this.payment_props.terms_of_service_url =
      '/checkout/policy/web/viewer.html?file=/checkout/payment_privacy.pdf';
    this.validate();
  }

  save() {
    if (this.validate()) {
      this.setPaymentsFields().then(() => {
        this.$rootScope.$emit('$paymentsEnabled');
      });
      if (this.paumentType === 'native') {
        this.currentState = this.states.step2;
      } else {
        this.currentState = this.states.step3;
      }
    }
  }

  close() {
    this.stopWatch();
    this.$modal.close(false);
  }

  watchUserFbAccess() {
    // document.cookie = '___access_payments_done=true; path=/; domain=chatfuel.com';
    if (this.$cookies.get('___access_payments_done') === 'true') {
      this.$cookies.put('___access_payments_done', 'false', {
        path: '/',
        domain: 'chatfuel.com',
      });

      if (this.fbWindows && !this.fbWindows.closed) {
        this.fbWindows.close();
        this.fbWindows = null;
      }
      window.focus();
      this.paymentsAccess = true;

      this.$rootScope.$emit('$set-payment-status-on', 'native');

      // this.PageService.cachedList(Math.random());
      // this.getPaymentsFields();
      this.close();
    } else if (this.fbWindows && !this.fbWindows.closed) {
      this.$timeout.cancel(this.fbWatchTimeout);
      this.fbWatchTimeout = this.$timeout(() => {
        this.watchUserFbAccess();
      }, 500);
    } else {
      this.fbWindows = null;
    }
  }

  start() {
    if (this.paymentsAccess) {
      // this.PageService.cachedList(Math.random());
      // this.currentState = this.states.step1;
      // this.getPaymentsFields();
      this.currentState = this.states.step3;
    } else if (!this.fbWindows || this.fbWindows.closed) {
      this.fbWindows = window.open(
        `${this.StoreService.getApiUrl().replace(
          /\/+$/,
          '',
        )}/access_payments?bot=${this.$rootScope.stateParams.botId}`,
        '_blank',
      );
      this.fbWindows.focus();

      this.$timeout(() => {
        this.watchUserFbAccess();
      }, 1000);
    } else {
      this.fbWindows.focus();
    }
  }

  stopWatch() {
    if (this.fbWindows) {
      this.fbWindows.close();
      this.fbWindows = null;
    }
    this.$timeout.cancel(this.fbWatchTimeout);
  }

  stripeConnect() {
    if (!this.fbWindows || this.fbWindows.closed) {
      this.fbWindows = window.open('', '_blank');
      this.fbWindows.focus();
      this.BotService.getStripeConnectUrl().then((stripeUrl) => {
        this.fbWindows.location.href = stripeUrl;
        window.fbWindows = this.fbWindows;
        this.$timeout(() => {
          this.watchConnectStripe();
        }, 1000);
      });
    } else {
      this.fbWindows.focus();
    }
  }

  stripeDisconnect() {
    this.bot.payments_stripe = false;
    this.BotService.stripeDisconnect();
  }

  watchConnectStripe() {
    let href;
    try {
      // eslint-disable-next-line prefer-destructuring
      href = this.fbWindows.location.href;
    } catch (error) {
      log.warn({ error });
    }

    if (this.fbWindows && href && href.indexOf('access_stripe_done') !== -1) {
      if (!this.fbWindows.closed) {
        this.fbWindows.close();
        this.fbWindows = null;
      }
      window.focus();
      this.bot.payments_stripe = true;
      this.$rootScope.$emit('$set-payment-status-on', 'stripe');
      const code = /code=([A-Za-z0-9_-]+)/.exec(href)[1];
      this.BotService.setStripeConnected(code);
    } else if (this.fbWindows && !this.fbWindows.closed) {
      this.$timeout.cancel(this.fbWatchTimeout);
      this.fbWatchTimeout = this.$timeout(() => {
        this.watchConnectStripe();
      }, 100);
    } else {
      this.fbWindows = null;
    }
  }

  setFocus() {
    setTimeout(() => {
      document.querySelector('.popup input').focus();
    }, 1);
  }

  go(type) {
    this.paumentType = type;
    this.currentState = this.states.step1;
    this.setFocus();
  }
}
