export default class ModalController {
  constructor($uibModalInstance) {
    'ngInject';

    this.$modal = $uibModalInstance;
  }

  close = () => {
    this.$modal.close(null);
  }
}
