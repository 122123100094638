import { clone } from 'ramda';
import { log } from 'cf-common/src/logger';

export default class ModalController {
  constructor(
    $uibModalInstance,
    $timeout,
    content,
    buttons,
    $scope,
    $rootScope,
    BlockService,
  ) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.content = content;
    this.buttons = buttons;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.BlockService = BlockService;

    this.buttonDisabled = false;

    this.$scope.$watch('vm.active', (newValue, oldValue) => {
      if (oldValue && !newValue) {
        this.content.block.referral_active = false;
        const refData = {
          referral: this._content.block.referral,
          referral_active: this.content.block.referral_active,
        };
        this.BlockService.saveReferral(refData, this.content.block.id);
      }
    });
  }

  $onInit() {
    this._content = clone(this.content);
    this.active = this._content.block.referral_active;
    if (!this._content.block.referral) {
      this._content.block.referral = this._content.title;
    }
    this.checkInputTitle();
  }

  ok() {
    const refData = {
      referral: this._content.block.referral,
      referral_active: this.active,
    };
    this.BlockService.saveReferral(refData, this.content.block.id)
      .then((res) => {
        this.content.block.referral = this._content.block.referral;
        this.content.block.referral_active = this.active;
        this.$modal.close(true);
      })
      .catch((e) => {
        if (e.status === 409) {
          this.$refError =
            'Conflict: Active link with such value already exists';
        }
      });
  }

  close() {
    this.$modal.close(false);
  }

  checkInputTitle() {
    if (!this._content.block.referral) {
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
    }
  }

  encodeLink() {
    return `https://m.me/${this.content.page}?ref=${encodeURIComponent(
      this._content.block.referral,
    )}`;
  }

  copyLink(event) {
    event.currentTarget.previousElementSibling.select();
    try {
      document.execCommand('copy');
      this.copied = true;
      this.$timeout(() => {
        this.copied = false;
      }, 1000);
      this.clearSelection();
    } catch (error) {
      log.verbose({ error });
    }
  }

  clearSelection() {
    if (document.selection) {
      document.selection.empty();
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }
}
