export function swapLink(StoreService) {
  'ngInject';

  const a = document.createElement('a');

  return function swapLinkInner(input) {
    a.href = StoreService.getMainUrl();
    a.host = `${input}.${a.host}`;

    return a.href;
  };
}

export function ordinal() {
  return function ordinalInner(n) {
    const s = ['th', 'st', 'nd', 'rd'];

    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };
}

export function removeTime() {
  return function removeTimeInner(title) {
    return (title || '').replace(/ \d{1,2}:\d{2} (am|pm)$/i, '');
  };
}

export function inverseSpecialChars() {
  return (input) => {
    const pattern = /(&lt;)|(&gt;)|(&amp;)/g;
    return input.replace(
      pattern,
      (str) =>
        ({
          '&lt;': '<',
          '&gt;': '>',
          '&amp;': '&',
        }[str]),
    );
  };
}

export function attributeDescription(StoreService) {
  'ngInject';

  return (attr) => StoreService.getSystemAttributeTooltip(attr);
}
