import { isValidUrl } from '../../utils/UrlUtils';

export default class ArtOneDomainWhitelistedController {
  constructor($timeout, $element) {
    'ngInject';

    this.$timeout = $timeout;
    this.$element = $element;
  }

  $onInit() {
    setTimeout(() => {
      this.$element[0].querySelector('input').focus();
    }, 100);
  }

  $onDestroy() {}

  rawDomainChange() {
    const url =
      (/^https?:\/\//i.test(this.rawDomain) ? '' : 'http://') + this.rawDomain;

    if (isValidUrl(url)) {
      const link = document.createElement('a');
      link.href = url;
      this.domain = link.host;
      this.error = false;

      if (this.testDomainsQty()) {
        this.ngModel.$setViewValue(this.domain);
      } else {
        this.ngModel.$setViewValue(null);
      }
    } else {
      this.domain = null;
      this.error = true;
      this.ngModel.$setViewValue(null);
    }
  }

  testDomainsQty() {
    if (!this.bot.domains_whitelist) {
      this.bot.domains_whitelist = [];
    }
    const dec =
      this.domain && this.bot.domains_whitelist.indexOf(this.domain) !== -1
        ? 1
        : 0;
    if (
      this.bot &&
      this.bot.domains_whitelist.length - dec >= this.bot.domains_whitelist_max
    ) {
      this.errorText = `Max ${this.bot.domains_whitelist_max} domains allowed. Remove a domain in <a href="/bot/${this.bot.id}/settings/">Settings</a> to make room.`;
      this.error = true;
      return false;
    }
    return true;
  }

  onInputBlur() {
    if (!this.error) {
      this.rawDomain = this.domain;
    }
    this.testDomainsQty();
  }
}
