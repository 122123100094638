import { clone } from 'ramda';
import { Level, log } from 'cf-common/src/logger';
import { getBlocksTitlesArchiveObservable } from '../../modern-components/Aside/BlocksGroupsQueries';
import { findById } from '../services/CollectionUtils';
import { createBlock } from '../../modern-components/Aside/Mutations/BlockMutations';
import client from '../services/ApolloService';
import { BLOCK_QUERY } from '../../modern-components/Plugins/common/PluginGQL';
import { ATTRIBUTES_QUERY } from '../../utils/AttributesUtils/AttributesUtilsGQL';
import { Platform } from '../../../@types/globalTypes';

export default (
  $http,
  $filter,
  $rootScope,
  StoreService,
  PluginCardService,
) => {
  'ngInject';

  return {
    show(blockId) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/blocks/${blockId}`,
      }).then((res) => res.data.result);
    },

    list(query) {
      const params = {};
      if (query) {
        params.query = query;
      }
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/blocks`,
        params,
      }).then((res) => res.data.result);
    },

    save(data) {
      if (data.id) {
        return this.update(data);
      }

      return this.create(data);
    },

    /**
     * @param data {{ parent_group: string }}
     * @returns {Promise}
     */
    create(data) {
      const parentGroup = data.parent_group;
      delete data.parent_group;

      if (!data.title) {
        data.title = null;
      }

      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/groups/${parentGroup}/blocks`,
      }).then((res) => {
        const { result } = res.data;
        return result;
      });
    },

    update2(data) {
      const lData = clone(data);

      if (lData.parent_group) {
        lData.group_id = lData.parent_group;
      }
      // data.position || (data.position = 0);
      const { id } = lData;

      delete lData.parent_group;
      delete lData.id;

      return $http({
        data: lData,
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${id}`,
        cacheOptions: {
          skipPreCache: true,
        },
      }).then((response) => {
        return response.data.result;
      });
    },

    update(data) {
      /**
       * Historically this method returned passed data instead of the actual
       * server response. To avoid breaking changes, this method keeps old
       * behavior; for correct behavior use update2
       */
      return this.update2(data).then(() => data);
    },

    postBlock(params, data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${params.blockId}`,
        cacheOptions: {
          need: false,
        },
      }).then((res) => res.data.result);
    },

    saveReferral(data, blockId) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/referral `,
      }).then(() => data);
    },

    createBroadcastBlock(sendOnUpdate = false) {
      const params = {};
      if (sendOnUpdate) {
        params.sendOnUpdate = true;
      }

      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/broadcast_blocks`,
        method: 'post',
        params,
      }).then((res) => res.data.result);
    },

    createBroadcastScheduleBlock(data) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/scheduled_entries`,
        method: 'post',
        data,
      }).then((res) => ({ id: res.data.result }));
    },

    createBroadcastSequenceBlock(data) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/sequences`,
        method: 'post',
        data,
      }).then((res) => ({ id: res.data.result }));
    },

    createBroadcastNowBlock() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/send_now_block`,
      }).then((res) => res.data.result);
    },

    removeBroadcastNowBlock() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/send_now_block/delete`,
      }).then((res) => res.data.result);
    },

    async detail(id) {
      const { errors, data } = await client.query({
        query: BLOCK_QUERY,
        variables: {
          botId: $rootScope.stateParams.botId,
          blockId: id,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      });

      if (errors) {
        // eslint-disable-next-line no-throw-literal
        throw errors?.[0]?.extensions?.response;
      }

      const block = data?.bot.block || {};

      client.query({
        query: ATTRIBUTES_QUERY,
        variables: {
          botId: $rootScope.stateParams.botId,
          platform: Platform.facebook,
        },
        fetchPolicy: 'network-only',
        context: {
          batchingDisabled: true,
        },
      });

      return {
        ...block,
        cards: JSON.parse(block.jsonCards),
      };
    },

    remove(id) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${id}/delete`,
      }).then((res) => res.data);
    },

    cloneBlock({ groupId, blockId, botId }) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/clone/${botId}/${groupId}`,
      }).then((res) => res.data.result);
    },

    broadcastNow(blockId) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/broadcast/now`,
      }).then((res) => res.data.result);
    },

    broadcastAutopost(data) {
      return $http({
        method: 'post',
        data,
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/autoposting`,
      }).then((res) => res.data.result);
    },

    messageUsButton(blockId, color, size, noReplace) {
      return $http({
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/messageus`,
        params: { color, size },
        method: 'get',
      }).then((res) => {
        const data = noReplace
          ? res.data.result
          : (res.data.result || '').replace(/<script>.*?<\/script>/i, '');
        return data;
      });
    },

    webPluginButton(blockId, color, size, noReplace) {
      return $http({
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/webplugin`,
        params: { color, size },
        method: 'get',
      }).then((res) => {
        const data = noReplace
          ? res.data.result
          : (res.data.result.replace || '')(/<script>.*?<\/script>/i, '');
        return data;
      });
    },

    clone(blockId, title) {
      return $http({
        url: `${StoreService.getApiUrl()}/blocks/${blockId}/clone${
          title ? `?title=${title}` : ''
        }`,
        method: 'post',
      }).then((res) => res.data.result);
    },

    duplicate(targetBlock, state, block, goto) {
      let saveCardCount = 0;

      block.cards = targetBlock.cards;

      targetBlock.cards.forEach((elem) => {
        elem.blockId = block.id;
        delete elem.id;
        ++saveCardCount;

        PluginCardService.save(elem).then(
          () => {
            --saveCardCount;
            if (!saveCardCount && goto) {
              goto(block);
            } else if (!saveCardCount && !goto) {
              state.go('app.structure.block', { id: block.id });
            }
          },
          () => {
            --saveCardCount;
            if (!saveCardCount && goto) {
              goto(block);
            } else if (!saveCardCount && !goto) {
              state.go('app.structure.block', { id: block.id });
            }
          },
        );
      });

      if (saveCardCount === 0 && goto) {
        goto(block);
      } else if (saveCardCount === 0 && !goto) {
        state.go('app.structure.block', { id: block.id });
      }
    },
    async reCreateRemovedBlockById(blockId) {
      const { botId } = $rootScope.stateParams;
      const blockTitlesArchive = await getBlocksTitlesArchiveObservable(botId)
        .take(1)
        .toPromise();
      const removedBlock = findById(blockId, blockTitlesArchive);
      return (
        removedBlock &&
        createBlock({
          botId,
          title: removedBlock.title,
          history: $rootScope.stateHistory,
        })
      );
    },
  };
};
