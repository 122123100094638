import template from './art-textarea-expand.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$artTextareaExpand',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    value: '=',
    index: '=',
    maxLength: '@',
    userAttributeSuggest: '@',
    ngKeydown: '&',
    ngKeyup: '&',
  },
});
