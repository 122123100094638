import ng from 'angular';

export default ng
  .module('app.common.draggableTextFields ', [])
  .directive('draggableTextFields', () => (scope, element) => {
    let drag = false;
    let sY;
    let event;

    element.on('mousedown', (e) => {
      drag = true;
      sY = e.screenY;

      // event = document.createEvent('MouseEvents');
      // event.initEvent('mousedown', true, true, window, 0, e.screenX, e.screenY, e.clientX, e.clientY, true, false, false, true, 0, null);
      event = new MouseEvent('mousedown', e);
    });

    ng.element(document).on('mouseup', (e) => {
      drag = false;
      sY = null;
      event = null;
    });

    element.on('mousemove', (e) => {
      if (drag && Math.abs(sY - e.screenY) > 6) {
        drag = false;
        element[0].parentElement.dispatchEvent(event);
      }
    });

    const listener = scope.$on('$destroy', () => {
      ng.element(document).off('mouseup');
      element.off('mousemove');
      element.off('mousedown');
      listener();
    });
  }).name;
