export default class AttrSelectorController {
  constructor($scope, $timeout, $element, $rootScope, StoreService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.StoreService = StoreService;
    this.$timeout = $timeout;

    this.notBlured = true;
    this.enter = false;

    this.listeners = [];

    this.listeners.push(
      $scope.$watch('vm.value', () => {
        if (this.value && this.input.innerText.trim() !== this.value.trim()) {
          this.localValue = this.value !== null ? this.value : '';
        }
        if (!this.value) {
          this.localValue = '';
        }

        this.designInput();
      }),
    );

    this.listeners.push(
      $scope.$watch('vm.setAttrFocus', () => {
        if (this.setAttrFocus) {
          this.input.focus();
        }
      }),
    );

    this.listeners.push(
      $scope.$on('$showPopUp', () => {
        this.popUpOpen = null;
      }),
    );

    this.listeners.push(
      $scope.$on('$angularMouseUp', () => {
        $timeout(() => {
          if (!this.setAttrFocus) {
            this.popUpOpen = null;
          }
        });
      }),
    );

    this.input = $element[0].querySelector('.cvp-input');
    this.popupScrollBox = $element[0].querySelector('.svp-popup');
  }

  $onInit() {
    this.designInput();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
  }

  elClick($event) {
    $event.stopPropagation();
    this.input.focus();
    this.placeCaretAtEnd(this.input);
  }

  onFocusInput() {
    if (this.updateVarsMap) {
      this.updateVarsMap();
    }
    // this.localValue = '';
    // this.value = '';
    this.valueTemp = '';
    // this.input.innerText = '';
    this.$rootScope.$broadcast('$showPopUp');
    this.popUpOpen = true;
    this.popupAct = 0;
    this.input.classList.add('on-focus-now');
  }

  onBlurInput() {
    if (this.vars.find((variable) => variable.name === this.value)) {
      this.localValue = this.value;
    } else {
      this.valueTemp = this.value;
      this.value = '';
      this.localValue = '';
    }
    this.input.classList.remove('on-focus-now');
    // this.placeCaretAtStart();
  }

  filterShow() {
    const result = {};
    const val = (this.valueTemp && this.valueTemp) || this.value || '';
    this.emptyPopUp = false;

    this.vars.forEach((value, ind) => {
      if (value.name.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
        result[value.name] = value.count;
        this.emptyPopUp = true;
      }
    });
    return result;
  }

  compileUserText(n) {
    return `${String(n).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')} ${
      Number(n) === 1 ? 'user' : 'users'
    }`;
  }

  onKeyDown(e) {
    const textVal = e.target.innerText.trim();

    if (textVal.length === 0) {
      this.localValue = '';
      e.target.innerText = '';
    }

    if (e.keyCode === 8) {
      if (textVal.length === 1) {
        this.localValue = '';
        e.target.innerText = '';
        this.update();
      }
      this.$timeout(() => {
        this.input.focus();
      }, 100);
    } else if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();

      const pars = this.filterShow();

      let count = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in pars) {
        if (Object.prototype.hasOwnProperty.call(pars, key)) {
          count++;
          if (count - 1 === this.popupAct) {
            this.setVar(key);
            break;
          }
        }
      }

      this.popupAct = 0;
      this.popUpOpen = null;

      this.enter = true;
      this.setFocusToValue();
    } else if (e.keyCode === 38) {
      if (this.popupAct > 0) {
        this.popupAct--;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      if (this.popupAct < Object.keys(this.filterShow()).length - 1) {
        this.popupAct++;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onLiClick(e, lVar) {
    e.stopPropagation();
    e.preventDefault();
    this.popupAct = 0;

    this.setVar(lVar);
    this.onSelect({ $value: lVar });
  }

  setVar(lVar) {
    this.value = lVar;
    this.localValue = this.value;
    this.popUpOpen = null;
    this.setFocusToValue();

    this.onSelect({ $value: this.localValue });
  }

  update(trim) {
    if (trim) {
      this.value = this.input.innerText.trim();
    } else {
      this.value = this.input.innerText;
    }
  }

  setPopUpScroll() {
    const actItemObj = this.popupScrollBox.querySelector('.act');
    if (actItemObj) {
      if (actItemObj.offsetTop - 48 < this.popupScrollBox.scrollTop) {
        this.popupScrollBox.scrollTop = actItemObj.offsetTop - 48;
      } else if (
        actItemObj.offsetTop + actItemObj.offsetHeight + 48 >
        this.popupScrollBox.scrollTop + this.popupScrollBox.offsetHeight
      ) {
        this.popupScrollBox.scrollTop =
          actItemObj.offsetTop +
          actItemObj.offsetHeight -
          this.popupScrollBox.offsetHeight +
          48;
      }
    }
  }

  placeCaretAtEnd(el) {
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  placeCaretAtStart() {
    if (this.notBlured) {
      this.$timeout(() => {
        this.input.focus();

        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(this.input, 0);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);

        this.notBlured = false;

        this.input.blur();
      }, 0).then(() => {
        this.notBlured = true;

        if (this.enter) {
          this.popupAct = 0;
          this.popUpOpen = null;
          this.setFocusToValue();

          this.enter = false;
        }
      });
    }
  }

  designInput() {
    setTimeout(() => {
      const needToDesign = this.input.classList.contains('ng-not-empty');
      const designWrapper = this.$element[0].querySelector(
        '.cvp-input-box__design-input-wrapper',
      );

      if (needToDesign) {
        designWrapper.classList.add('active');
      } else {
        designWrapper.classList.remove('active');
      }
    }, 0);
  }
}
