export default class ModalController {
  constructor($uibModalInstance, payInfo, MonetizationService) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.payInfo = payInfo;
    this.MonetizationService = MonetizationService;
  }

  $onInit() {
    this.MonetizationService.getPaymentsInformation().then((res) => {
      this.paymentsInformation = res;
    });
  }

  cancelProPlan() {
    this.$modal.close(true);
  }

  close() {
    this.$modal.close(null);
  }
}
