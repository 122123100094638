import angular from 'angular';
import tooltip from 'angular-ui-bootstrap/src/tooltip';
import position from 'angular-ui-bootstrap/src/position';
import stacked from 'angular-ui-bootstrap/src/stackedMap';
import TooltipConfig from './tooltip.config';
import InputContol from './input-control.js';

export default angular
  .module('app.common.tooltip', [position, stacked, tooltip])
  .directive('inputControl', InputContol)
  .config(TooltipConfig).name;
