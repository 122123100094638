import template from './art-big-dropdown.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: '$artBigDropdown',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    ngModel: '=',
    items: '=',
    index: '=',
    cardId: '=',
    showItems: '=',
    locked: '=',
  },
});
