import { getAttributesQueryObservable } from '../../../utils/AttributesUtils/AttributesUtils';
import { Platform, VariableSuggestType } from '../../../../@types/globalTypes';

export default class ModalController {
  constructor(
    $uibModalInstance,
    $timeout,
    $scope,
    varName,
    openHelp,
    StoreService,
    PeopleService,
    $rootScope,
  ) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.varName = varName;
    this.StoreService = StoreService;
    this.PeopleService = PeopleService;

    if (!this.varName) {
      this.varName = '';
    }

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.$showSelect = false;
      });
    });

    if (openHelp) {
      this.openHelpGo(true);
    }

    this.attributesSubscription = getAttributesQueryObservable(
      $rootScope.stateParams.botId,
      VariableSuggestType.omnibox,
      null,
    ).subscribe((allBotAttributesForSuggest) => {
      this.attributes = this.PeopleService.filterAndSortCustomFirstAttributes(
        allBotAttributesForSuggest,
      );
    });

    $scope.$on('$destroy', () => {
      if (this.attributesSubscription) {
        this.attributesSubscription.unsubscribe();
      }
    });
  }

  openHelpGo(v) {
    this.openHelp = v;
    const obj = window.angular.element(
      document.querySelector('.popup.user-property'),
    );

    if (v) {
      obj.addClass('openHelp');
    } else {
      obj.removeClass('openHelp');
    }
  }

  ok() {
    this.$modal.close({ varName: this.varName });
  }

  close() {
    this.$modal.close(null);
  }

  inputFocus() {
    this.$timeout(() => {
      document.querySelector('.create input').focus();
    });
  }
}
