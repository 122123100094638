import controller from './controller';

export default () => ({
  restrict: 'A',
  scope: {
    link: '=',
  },
  controller,
  controllerAs: 'fbtbc',
  bindToController: true,
});
