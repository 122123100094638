import ng from 'angular';

function fbIframeClick() {
  return (scope, element) => {
    let mouseOver = false;

    setTimeout(() => {
      window.focus();
    }, 500);

    element.on('mouseenter', () => {
      mouseOver = true;
    });

    element.on('mouseleave', () => {
      mouseOver = false;
    });

    ng.element(window).on('blur', (e) => {
      if (mouseOver) {
        setTimeout(() => {
          window.open('http://facebook.com/1125538687485749', '_blank');
        }, 500);
      }
    });

    const listener = scope.$on('$destroy', () => {
      element.off('mouseenter');
      element.off('mouseleave');
      ng.element(window).off('blur');
      listener();
    });
  };
}

export default ng
  .module('app.common.fbIframeClick', [])
  .directive('fbIframeClick', fbIframeClick).name;
