import ng from 'angular';

/**
 * @ngdoc directive
 * @name cfAutofocus
 * @restrict A
 */
export default ng
  .module('app.common.cfAutofocus', [])
  .directive('cfAutofocus', ($timeout) => {
    'ngInject';

    return (scope, $element) => {
      $element[0].focus();
    };
  }).name;
