import ng from 'angular';

export default ng
  .module('app.common.artOnlyNumberCost', [])
  .directive('artOnlyNumberCost', () => (scope, element) => {
    const keyCode = [
      8,
      9,
      37,
      39,
      46,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      110,
      190,
    ];

    element.on('keydown', (event) => {
      if (keyCode.indexOf(event.keyCode) === -1) {
        event.preventDefault();
      } else if (event.keyCode === 190) {
        if (
          !(
            element[0].value.length === 0 ||
            element[0].value.indexOf('.') !== -1
          )
        ) {
          event.target.value = `${event.target.value}.`;
        }
        event.preventDefault();
      } else if (
        element[0].value.indexOf('.') !== -1 &&
        element[0].value.length - element[0].value.indexOf('.') > 2 &&
        event.keyCode > 47
      ) {
        event.preventDefault();
      }
    });

    element.on('blur', () => {
      let val = element[0].value.trim();
      if (val.length === 0) {
        return;
      }
      if (val.indexOf('.') === -1) {
        val += '.00';
      } else {
        while (val.length - val.indexOf('.') < 3) {
          val += '0';
        }
      }
      element[0].value = val;
      element.trigger('change');
    });

    scope.$on('$destroy', () => {
      element.off('keydown');
      element.off('blur');
    });
  }).name;
