import ng from 'angular';

function noInputSelectText() {
  return (scope, element) => {
    element.on('click', () => {
      const e = element.get(0);

      if (window.getSelection) {
        const s = window.getSelection();
        if (s.setBaseAndExtent) {
          s.setBaseAndExtent(e, 0, e, e.innerText.length - 1);
        } else {
          const r = document.createRange();
          r.selectNodeContents(e);
          s.removeAllRanges();
          s.addRange(r);
        }
      } else if (document.getSelection) {
        const s = document.getSelection();
        const r = document.createRange();
        r.selectNodeContents(e);
        s.removeAllRanges();
        s.addRange(r);
      } else if (document.selection) {
        const r = document.body.createTextRange();
        r.moveToElementText(e);
        r.select();
      }
    });

    scope.$on('$destroy', () => {
      element.off('click');
    });
  };
}

export default ng
  .module('app.common.noInputSelectText', [])
  .directive('noInputSelectText', noInputSelectText).name;
