import confirmDeleteGroupController from '../confirm-delete-group/controller';

export default class confirmDeleteBotController extends confirmDeleteGroupController {
  constructor($uibModalInstance, $timeout, content, $uibModal, $document) {
    'ngInject';

    super($uibModalInstance, $timeout, content, $uibModal, $document);
    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.$document = $document;
    this.invalid = true;
    this.content = content;
  }
}
