import defaultBotPic from '../../assets2/images/av_def_bot.png';

export default class BotNewController {
  constructor(
    $scope,
    $rootScope,
    $interpolate,
    $sce,
    $timeout,
    $element,
    BotService,
    ModalService,
    PageService,
    CacheControlService,
    API_ERROR_MSG,
  ) {
    'ngInject';

    this.$sce = $sce;
    this.$rootScope = $rootScope;
    this.$interpolate = $interpolate;
    this.$timeout = $timeout;
    this.$element = $element;
    this.ModalService = ModalService;
    this.BotService = BotService;
    this.PageService = PageService;
    this.CacheControlService = CacheControlService;
    this.API_ERROR_MSG = API_ERROR_MSG;

    this.pageAvatar = defaultBotPic;

    this.statusTitle = 'draft';

    this.updateBotStatus();

    this.listeners = [];

    this.listeners.push(
      $scope.$on('$angularClick', () => {
        $scope.$evalAsync(() => {
          this.menuShowed = false;
        });
      }),
    );
    this.listeners.push(
      $rootScope.$on('$hideBotMenu', () => {
        this.menuShowed = false;
      }),
    );

    this.listeners.push(
      $scope.$on('$updateBotStatus', (e, data) => {
        if (this.bot.id === data.id) {
          this.bot.status.status = data.status;
          this.bot.status.read_only = data.read_only;
          this.bot.status.page = data.page;
          this.updateBotStatus();
        }
      }),
    );

    this.listeners.push(
      $scope.$watch('vm.stats', (val) => {
        if (val && val.length) {
          this.botStats = this.stats.find(
            (stat) => stat.page_id === this.bot.status.page,
          );
        }
      }),
    );

    this._showPageAvatar();
    this.preventClickIfEdited();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());

    this.$element.find('.content, .button').off('click');
  }

  preventClickIfEdited() {
    const elements = this.$element.find('.content, .button');

    elements.on('click', () => {
      if (this.$edited) {
        return false;
      }
      return undefined;
    });
  }

  _showPageAvatar() {
    if (this.bot.status.page) {
      this.pageAvatar = this.bot.status.page_info.picture || defaultBotPic;
    }
  }

  updateBotStatus() {
    this.statusTitle = this.BotService.getStatusText(this.bot);
    this.buttonTitle = this.BotService.getWizardButtonText(this.bot);
  }

  $timeZone() {
    let timeZone = `GMT+${this.bot.timezone_offset / 3600}`;

    if (this.bot.timezone_name.toLowerCase() !== 'unknown') {
      timeZone += ` ${this.bot.timezone_name}`;
    }

    return timeZone;
  }

  toClone(event) {
    this.menuShowed = false;
    event.preventDefault();

    this.ModalService.cloneBot(this.bot).then((botTitle) => {
      if (botTitle) {
        this.clone(botTitle);
      }
    });
  }

  clone(newTitle) {
    this.$rootScope.$emit('startCloning');
    this.BotService.create({ title: newTitle })
      .then((newBot) => {
        return this.BotService.cloneFrom(newBot.id, this.bot.id);
      })
      .then((newBot) => {
        this.bots.push(newBot);
        this.$rootScope.$emit('stopCloning');
        this.CacheControlService.removeItem('/bots');
      })
      .catch(() => {
        this.$rootScope.$emit('stopCloning');
      });
  }

  toMoveTo(event) {
    this.menuShowed = false;
    event.preventDefault();

    this.ModalService.moveToBot(this.bot).then((botId) => {
      if (botId) {
        this.moveTo(botId);
      }
    });
  }

  moveTo(botId) {
    this.menuShowed = false;
    this.BotService.cloneFrom(this.bot.id, botId);
  }

  toRemove(event) {
    this.menuShowed = false;
    event.preventDefault();

    this.ModalService.confirmDeleteBot({
      bot: this.bot,
    }).then((isConfirmed) => isConfirmed && this.remove());
  }

  remove() {
    this.BotService.remove(this.bot.id).then(() => {
      const i = this.bots.findIndex((item) => item === this.bot);
      if (i !== -1) {
        this.bots.splice(i, 1);
      }
      this.CacheControlService.removeItem('/bots');
    });
  }

  toEdit(event) {
    this.menuShowed = false;
    event.preventDefault();
    this.$edited = true;

    setTimeout(() => {
      this.$element.find('textarea').focus();
    }, 10);
  }

  edit(event) {
    this.BotService.update(this.bot.id, { title: this.bot.title }).then(() => {
      this.CacheControlService.removeItem('/bots');
    });

    event.stopPropagation();

    this.$edited = false;
  }

  go() {
    if (!this.$edited) {
      this.$rootScope.stateHistory.push(`/bot/${this.bot.id}/settings`);
    }
  }

  goWizard(event) {
    event.preventDefault();
    this.menuShowed = false;

    const target = this.BotService.getWizardButtonStep(this.bot);
    if (target <= 0) {
      switch (target) {
        case -1:
          this.$rootScope.stateHistory.push(`/bot/${this.bot.id}/structure`);
          this.$timeout(() => {
            this.$rootScope.stateHistory.push(`/bot/${this.bot.id}/settings`);
          });
          break;
        case -2:
          this.$rootScope.stateHistory.push(`/bot/${this.bot.id}/structure`);
          break;
        default:
      }
    }
  }

  showMenu(event) {
    this.$rootScope.$emit('$hideBotMenu');
    event.preventDefault();
    event.stopPropagation();
    this.menuShowed = true;
  }

  onBotReadOnlyChange() {
    if (this.bot.status.read_only) {
      this.bot.status.status = 'pending';
    } else if (
      !this.bot.status.read_only &&
      this.bot.status.status === 'pending'
    ) {
      this.bot.status.status = 'published';
    }

    this.BotService.update(this.bot.id, {
      timezone_offset: this.bot.timezone_offset,
      timezone_name: 'Unknown',
      title: this.bot.title,
      read_only: this.bot.status.read_only,
    });

    this.$rootScope.$broadcast('$updateBotStatus', {
      id: this.bot.id,
      status: this.bot.status.status,
      read_only: this.bot.status.read_only,
    });
  }

  positiveNumber(current, last) {
    if (current === undefined || last === undefined) {
      return 0;
    }
    return Math.abs(current - last);
  }

  goToConfigurePage() {
    this.$rootScope.stateHistory.push(`/bot/${this.bot.id}/settings`);
  }

  updateDiscount($event) {
    this.titleDiscount = 100 - $event.target.value.length;
  }
}
