export default class ModalController {
  constructor($scope, $uibModalInstance, BotService, movedBot) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.BotService = BotService;
    this.movedBot = movedBot;

    this.bot = null;

    this.loadBots();

    this.$showSelect = false;

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.$showSelect = false;
      });
    });
  }

  loadBots() {
    this.BotService.cachedList().then((bots) => {
      this.bots = bots;
    });
  }

  ok() {
    this.$modal.close(this.bot.id);
  }

  close() {
    this.$modal.close(null);
  }
}
