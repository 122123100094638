import escapeStringRegexp from 'escape-string-regexp';
import template from './input-control.html';

/**
 * InputControlDirective
 * @param {Object} $timeout
 * @return {Object}
 */
function InputControlDirective($timeout) {
  'ngInject';

  return {
    restrict: 'E',
    replace: false,
    require: '?ngModel',
    scope: {
      inputId: '@',
      inputPlaceholder: '@',
      serverError: '=',
      onSave: '&',
      errorPosition: '@',
    },
    template,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    link,
  };

  /**
   * link
   * @param {Object} scope
   * @param {Object} element
   * @param {Object} attrs
   * @param {Object} ngModel
   * @return undefined
   */
  function link(scope, element, attrs, ngModel) {
    if (!ngModel) {
      return;
    }

    let event;
    const ENTER_KEY = 13;
    const vm = scope;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const onValidate = () => validate(vm, element, attrs, ngModel, event);
    const onChange = () => ngModel.$setViewValue(vm.value);
    const $render = () => {
      vm.value = ngModel.$modelValue;
      return vm.value;
    };
    const $input = element.find('input');

    $input.on('keydown', (e) => {
      if (e.keyCode !== ENTER_KEY) {
        return;
      }
      event = e;
      onValidate();
    });

    $input.on('blur', (e) => {
      event = e;
      onValidate();
    });

    vm.$watch(
      () => vm.serverError,
      (error) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        showServerError(vm, $input, ngModel, error);
      },
      true,
    );

    vm.$watch(
      () => vm.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        hideServerError(vm, $input, ngModel, vm.serverError);
      },
      true,
    );

    vm.message = '';
    vm.id = attrs.inputId;
    vm.placeholder = attrs.inputPlaceholder;
    vm.onChange = onChange;
    ngModel.$render = $render;
  }

  /**
   * validate
   * @param {Object} vm
   * @param {Object} element
   * @param {Object} attrs
   * @param {Object} ngModel
   * @return undefined
   */
  function validate(vm, element, attrs, ngModel, event) {
    const message = (message) => {
      vm.message = message;
      return message;
    };
    const $element = angular.element(event.target);
    const key =
      Object.keys(ngModel.$error || {})
        .filter((e) => e)
        .shift() || '';

    if (ngModel.$error.server) {
      return;
    }

    if (ngModel.$invalid) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      message(getMessage(attrs, key));
      $timeout(() => $element.triggerHandler('onErrorShow'));
    } else {
      $timeout(() => $element.triggerHandler('onErrorHide'));
      vm.onSave();
    }

    vm.$apply();
  }

  /**
   * showServerError
   * @param {Object} vm
   * @param {Object} $input
   * @param {Object} ngModel
   * @param {Object} message
   * @return undefined
   */
  function showServerError(vm, $input, ngModel, message) {
    if (!message) {
      return;
    }

    ngModel.$setValidity('server', false);
    vm.message = message;

    $timeout(() => $input.triggerHandler('onErrorShow'));
  }

  /**
   * hideServerError
   * @param {Object} vm
   * @param {Object} $input
   * @param {Object} ngModel
   * @param {Object} message
   * @return undefined
   */
  function hideServerError(vm, $input, ngModel, message) {
    if (!message) {
      return;
    }

    ngModel.$setValidity('server', true);
    vm.message = null;
    vm.serverError = null;

    $timeout(() => $input.triggerHandler('onErrorHide'));
  }

  /**
   * getMessage
   * @param {Object} attrs
   * @param {String} type
   * @return {String}
   */
  function getMessage(attrs, type) {
    const MESSAGE = 'message';
    const ERROR = 'Error';

    const key = Object.keys(attrs)
      .filter((e) => e)
      .filter((attr) =>
        new RegExp(escapeStringRegexp(`${type}${MESSAGE}`, 'i')).test(attr),
      )
      .shift();

    return attrs[key] || ERROR;
  }
}

export default InputControlDirective;
