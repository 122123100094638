import { canView, canEdit } from '../services/RoleService';
import client from '../services/ApolloService';

export default class BlocksSuggestSequencesController {
  constructor(
    $scope,
    $element,
    $timeout,
    $rootScope,
    SuggestSequencesService,
    OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.SuggestSequencesService = SuggestSequencesService;
    this.listeners = [];
    this.canView = canView;
    this.canEdit = canEdit;
    this.omniboxSequencesTitlesMapQuery = OMNIBOX_SEQUENCES_TITLES_MAP_QUERY;
    this.sequencesTitlesMap = undefined;

    this.listeners.push(
      this.$scope.$on('$angularClick', () => {
        this.removeFocus();
      }),
    );

    this.listeners.push(
      this.$scope.$on('$removedLine', () => {
        this.$timeout(() => {
          this.fillSelectedBlocksArray();
        });
      }),
    );

    this.listeners.push(
      this.$scope.$watchCollection('vm.blocks', () => {
        this.fillSelectedBlocksArray();
      }),
    );
  }

  $onInit() {
    this.subscribeToSequencesList();
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());

    if (this.sequencesListSubscription) {
      this.sequencesListSubscription.unsubscribe();
    }
  }

  /**
   * fillSelectedBlocksArray()
   * fill our empty local selectedBlocks array
   * with blocks from parent controller.
   */
  fillSelectedBlocksArray() {
    if (!this.sequencesTitlesMap) {
      return;
    }
    this.selectedBlocks = this.blocks.map((block) =>
      this.sequencesTitlesMap.find((item) => block === item.id),
    );
    this.selectedBlocks = this.selectedBlocks.filter(Boolean); // remove all deleted segments;
    // mutate this block  so if plugin will be saved removed items will be cleaned;
    this.blocks.length = 0;
    this.blocks.push(
      ...this.selectedBlocks.map((selectedBlock) => selectedBlock.id),
    );
  }

  /**
   * setFocus()
   * @param: event
   * it used for adding dropdown to our page (look ng-if in template).
   * After DOM rendered, call moveDropdownToInput function width param our event
   * and scrollToInput function width param our event
   */
  setFocus(e) {
    this.SuggestSequencesService.setActiveDropdown(this.$scope.$id, this.index);

    setTimeout(() => {
      this.moveDropdownToInput(e);
      this.scrollToInput(e);
    }, 0);
  }

  /**
   * removeFocus()
   * close active dropdown
   */
  removeFocus() {
    this.SuggestSequencesService.closeActiveDropdown();
  }

  /**
   * moveDropdownToInput()
   * @param: event
   * just mark up function.
   * because we need overflow scroll in container,
   * we made dropdown with sequences list out of input container.
   * But we need move this dropdown to input, because it's correct.
   */
  moveDropdownToInput(e) {
    const inputAreaWidth = this.$element[0].querySelector('.bd-area')
      .offsetWidth;
    const currentInputOffset = e.currentTarget.offsetParent.offsetLeft;
    const dropdown = this.$element[0].querySelector('.bd-input__bd-items');
    const allowedDropdownOffset = inputAreaWidth - dropdown.offsetWidth;
    let dropdownOffset = null;

    if (currentInputOffset > allowedDropdownOffset) {
      dropdownOffset = allowedDropdownOffset;
    } else {
      dropdownOffset = currentInputOffset;
    }

    dropdown.style.left = `${dropdownOffset}px`;
    dropdown.classList.add('active');
  }

  /**
   * scrollToInput()
   * @param: event
   * just mark up function.
   * because we need scroll to input, if it is offset some distance
   * from editable view.
   */
  scrollToInput(e) {
    const scrollableContainer = e.currentTarget.parentNode.parentNode;
    const allowScrollWidth = scrollableContainer.scrollWidth;

    scrollableContainer.scrollLeft = allowScrollWidth;
  }

  /**
   * selectBlock()
   * @param: blockId
   * it calles only when clicked on chosen item in dropdown
   */
  selectBlock(blockId, selectedBlock) {
    this.selectedBlocks.push(selectedBlock);
    this.sequenceGroupTitle = '';
    this.saveBlock(blockId);
  }

  /**
   * keyDownValidation()
   * @param: e
   * it calles only when in input key was pressed
   */
  keyDownValidation(e) {
    const key = {
      tabulation: 9,
      backspace: 8,
    };

    switch (e.keyCode) {
      case key.tabulation:
        this.removeFocus();
        break;

      case key.backspace:
        this.backspaceManager(e);
        break;

      default:
        return false;
    }

    return undefined;
  }

  /**
   * backspaceManager()
   * @param: e
   */
  backspaceManager(e) {
    const input = e.currentTarget;

    if (input.selectionStart === 0 && this.selectedBlocks.length) {
      this.removeFromSelectedBlocks(e, this.selectedBlocks.length - 1);

      setTimeout(() => {
        this.moveDropdownToInput(e);
      }, 0);
    }
  }

  /**
   * removeFromSelectedBlocks()
   * @param: index
   * splice array object by index
   */
  removeFromSelectedBlocks(e, index) {
    e.stopPropagation();

    this.selectedBlocks.splice(index, 1);

    this.deleteBlock(index);
  }

  /**
   * saveBlock()
   * @param: blockId
   * returned data to parent controller
   */
  saveBlock(blockId) {
    this.blocks.push(blockId);
    this.$scope.$emit('$updateBlocksInSuggest', { index: this.index });
  }

  /**
   * deleteBlock()
   * @param: index
   * returned data to parent controller
   */
  deleteBlock(index) {
    this.blocks.splice(index, 1);
    this.$scope.$emit('$updateBlocksInSuggest', { index: this.index });

    this.$timeout(() => {
      this.fillSelectedBlocksArray();
    });
  }

  subscribeToSequencesList() {
    this.sequencesListSubscription = client
      .watchQuery({
        query: this.omniboxSequencesTitlesMapQuery,
        variables: {
          botId: this.$rootScope.stateParams.botId,
        },
      })
      .subscribe(
        ({
          data: {
            bot: { sequencesTitlesMap },
          },
        }) => {
          this.sequencesTitlesMap = sequencesTitlesMap;
          this.fillSelectedBlocksArray();
        },
      );
  }
}
