import template from './art-one-domain-whitelisted.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  restrict: 'E',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    bot: '<',
  },
};
