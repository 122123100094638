import { uniq } from 'ramda';
import client from '../services/ApolloService';
import {
  FULL_NATIVE_BOTS_LIST_QUERY,
  BOT_ALLOWED_PLATFORMS,
} from '../services/GQLqueries/BotGQService.const';
import { ATTRIBUTE_VALUES_QUERY } from '../../utils/AttributesUtils/AttributesUtilsGQL';

// eslint-disable-next-line no-unused-vars
const UTC_LIST = [
  { offset: -43200, name: 'Baker Island, Howland Island' },
  { offset: -39600, name: 'Niue, American Samoa' },
  { offset: -36000, name: 'Papeete, Honolulu' },
  { offset: -34200, name: 'French Polynesia' },
  { offset: -32400, name: 'Anchorage' },
  { offset: -28800, name: 'Los Angeles, Vancouver, Tijuana' },
  { offset: -25200, name: 'Phoenix, Calgary, Ciudad Juarez' },
  { offset: -21600, name: 'Chicago, Winnipeg, Mexico City' },
  { offset: -18000, name: 'New York, Toronto, Havana' },
  { offset: -14400, name: 'Santiago, Halifax, Santo Domingo' },
  { offset: -12600, name: "St. John's" },
  { offset: -10800, name: 'Buenos Aires, Montevideo, Sao Paulo' },
  { offset: -7200, name: 'South Sandwich Islands' },
  { offset: -3600, name: 'Cape Verde, Azores islands' },
  { offset: 0, name: 'London, Dublin, Lisbon' },
  { offset: 3600, name: 'Berlin, Paris, Rome' },
  { offset: 7200, name: 'Helsinki, Cairo, Jerusalem' },
  { offset: 10800, name: 'Moscow, Istanbul, Baghdad' },
  { offset: 12600, name: 'Tehran' },
  { offset: 14400, name: 'Baku, Dubai, Samara' },
  { offset: 16200, name: 'Kabul' },
  { offset: 18000, name: 'Karachi, Tashkent, Yekaterinburg' },
  { offset: 19800, name: 'Delhi, Colombo' },
  { offset: 20700, name: 'Kathmandu' },
  { offset: 21600, name: 'Almaty, Dhaka, Omsk' },
  { offset: 23400, name: 'Yangon' },
  { offset: 25200, name: 'Jakarta, Bangkok, Hanoi' },
  { offset: 28800, name: 'Beijing, Singapore, Kuala Lumpur' },
  { offset: 30600, name: 'Pyongyang' },
  { offset: 31500, name: 'Eucla' },
  { offset: 32400, name: 'Seoul, Tokyo, Yakutsk' },
  { offset: 34200, name: 'Adelaide, Broken Hill, Darwin' },
  { offset: 36000, name: 'Port Moresby, Sydney, Vladivostok' },
  { offset: 37800, name: 'Lord Howe Island' },
  { offset: 39600, name: 'Noumea' },
  { offset: 43200, name: 'Auckland, Suva' },
  { offset: 45900, name: 'Chatham Islands' },
  { offset: 46800, name: 'Apia' },
  { offset: 48600, name: 'Chatham Islands DST' },
  { offset: 50400, name: 'Line Islands' },
];

export default (
  $http,
  $rootScope,
  $q,
  $timeout,
  $injector,
  StoreService,
  // MonetizationService,
) => {
  'ngInject';

  const userAttributesValuesCache = {};

  const getRouteBotId = () => {
    return $rootScope.stateParams.botId;
  };

  return {
    botsReadyCallback: null,

    list() {
      return this.cachedList(false);
    },

    getBotById(id, refresh = false) {
      if (!id) {
        throw new Error('You should provide id to BotService.getBotById');
      }
      return this.cachedList(refresh).then((res) =>
        res.find((bot) => bot.id === id),
      );
    },

    async cachedList(refresh) {
      const {
        data: {
          bots: [...bots],
        },
      } = await client.query({
        query: FULL_NATIVE_BOTS_LIST_QUERY,
        fetchPolicy: refresh ? 'network-only' : 'cache-first',
      });
      bots.forEach((item) => {
        if (!item.first_block) {
          item.first_block = '';
        }
      });
      bots.date_added = parseInt(bots.date_added, 10);
      if (this.botsReadyCallback) {
        this.botsReadyCallback(bots);
      }
      return bots;
    },

    async getCurrentBotPlatforms() {
      return client.query({
        query: BOT_ALLOWED_PLATFORMS,
        variables: { botId: getRouteBotId() },
      });
    },

    create(data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots`,
      }).then((res) => res.data.result);
    },

    update(id, data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${id}`,
      }).then((res) => res.data.result);
    },

    show() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}`,
      }).then((res) => {
        if (!res.data.result.first_block) {
          res.data.result.first_block = '';
        }
        return res.data.result;
      });
    },

    remove(id) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${id}/delete`,
      }).then((res) => res.data.result);
    },

    setPayments(id, data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${id}/payment_props`,
      }).then((res) => res.data.result);
    },

    getPayments(id) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${id}/payment_props`,
      }).then((res) => res.data);
    },

    cloneFrom(botId, fromBotId) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${botId}/clone_from/${fromBotId}`,
      }).then((res) => {
        return res.data.result;
      });
    },

    appParams(id) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${id}/app_params`,
      }).then((res) => res.data.result);
    },

    drafts() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/drafts`,
        cacheOptions: {
          need: true,
        },
      }).then((res) => res.data.result);
    },

    templates() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/template_groups`,
        cacheOptions: {
          need: true,
        },
      }).then((res) => res.data.result);
    },

    basicStats() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/basic_stats`,
        cacheOptions: {
          need: true,
          lifetime: 3600000,
        },
      }).then((res) => res.data.result);
    },

    testPageButton(botId, color, size) {
      return $http({
        method: 'get',
        params: { color, size },
        url: `${StoreService.getApiUrl()}/bots/${botId}/testpage`,
      }).then((res) =>
        (res.data.result || '').replace(/<script>.*?<\/script>/i, ''),
      );
    },

    testAdminChatButton(pageId, color, size) {
      return $http({
        method: 'get',
        params: { color, size },
        url: `${StoreService.getApiUrl()}/pages/${pageId}/admin_button`,
        cacheOptions: {
          need: true,
        },
      }).then((res) =>
        (res.data.result || '').replace(/<script>.*?<\/script>/i, ''),
      );
    },

    messageUsButton(botId, color, size) {
      return $http({
        url: `${StoreService.getApiUrl()}/bots/${botId}/messageus`,
        params: { color, size },
        method: 'get',
        cacheOptions: {
          need: true,
        },
      }).then((res) => res.data.result);
    },

    getWizardButtonText(lbot) {
      let status = 'draft';
      let res;

      if (lbot && lbot.status && lbot.status.status) {
        // eslint-disable-next-line prefer-destructuring
        status = lbot.status.status;
      }

      switch (status) {
        case 'connected':
          res = 'Submit for review';
          break;
        case 'pending':
          res = 'Check status';
          break;
        case 'published':
          res = 'Rerun wizard';
          break;
        default:
          res = 'connect to facebook';
      }

      return res;
    },

    getStatusText(lbot) {
      let status = 'draft';
      let readOnly = false;
      let res;

      if (lbot && lbot.status && lbot.status.status) {
        // eslint-disable-next-line prefer-destructuring
        status = lbot.status.status;
        readOnly = lbot.status.read_only;
      }

      switch (status) {
        case 'connected':
          res = 'connected to the page';
          break;
        case 'pending':
          res = 'pending';
          break;
        case 'published':
          res = 'connected to the page';
          break;
        default:
          res = 'draft';
      }

      if (readOnly) {
        //  res += ', read-only';
      }

      return res;
    },

    getWizardButtonStep(lbot) {
      let status = 'draft';
      let res;

      if (lbot && lbot.status && lbot.status.status) {
        // eslint-disable-next-line prefer-destructuring
        status = lbot.status.status;
      }

      switch (status) {
        case 'connected':
          res = 3;
          break;
        case 'pending':
          res = -2;
          break;
        case 'published':
          res = 1;
          break;
        default:
          res = -1;
      }

      return res;
    },

    getAiLanguages() {
      return $http({
        url: `${StoreService.getApiUrl()}/witai/languages`,
        method: 'get',
        cacheOptions: {
          need: true,
        },
      })
        .then((res) => res.data.result)
        .catch((e) => {
          console.error(e);
        });
    },

    getMenu() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/menu`,
      }).then((res) => res.data.result);
    },

    setMenu(data) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/menu`,
      }).then((res) => res.data.result);
    },

    getInvite() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/invite`,
      }).then((res) => res.data.result);
    },

    removeAdmin(uId) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${
          $rootScope.stateParams.botId
        }/delete_admin/${uId}`,
      }).then((res) => res.data.result);
    },

    unbindBot() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/unbind`,
      }).then((res) => res.data.result);
    },

    userCount(data, withInstagram = false) {
      return $http({
        data,
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/user_count?withInstagram=${withInstagram}`,
      }).then((res) => res.data.result);
    },

    getSettingNotifications() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/notifications`,
        cacheOptions: {
          need: true,
          lifetime: 60000,
        },
      }).then((res) => res.data.result);
    },

    getBroadcastStats(fromDate) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/broadcast_stats?limit=50&from=${
          fromDate || ''
        }`,
      }).then((res) => res.data.result);
    },

    parameterSuggest(attributeName, query) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/parameter_suggest?attribute_name=${attributeName}&prefix=${query.trim()}`,
      }).then((res) => res.data.result);
    },

    resetApiToken() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/reset_api_token`,
      }).then((res) => res.data.result);
    },

    getMenuLanguages(refresh) {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/menu/languages`,
        cacheOptions: {
          need: true,
          refresh,
        },
      }).then((res) => res.data.result);
    },

    stripeDisconnect() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/payments/stripe/disconnect/${getRouteBotId()}`,
      }).then((res) => res.data.result);
    },

    getStripeConnectUrl() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/payments/stripe/connect`,
      }).then((res) => res.data.result);
    },

    setStripeConnected(code) {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/payments/stripe/auth?botId=${getRouteBotId()}&code=${code}`,
      }).then((res) => res.data.result);
    },

    yandexLogin() {
      return $http({
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/yandex/share/login`,
      }).then((res) => res.data.result);
    },

    yandexLogout() {
      return $http({
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/yandex/share/logout`,
      }).then((res) => res.data.result);
    },

    domainWhitelist(data) {
      return $http({
        method: 'post',
        data,
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/domains_whitelist`,
      }).then((res) => res.data);
    },

    messengerExtensions(data) {
      return $http({
        method: 'post',
        data,
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/button_whitelist`,
      }).then((res) => res.data);
    },

    async getSuggestValuesAll(attribute, prefix, refresh) {
      try {
        const PeopleService = $injector.get('PeopleService');
        const { data } = await client.query({
          query: ATTRIBUTE_VALUES_QUERY,
          fetchPolicy: refresh ? 'network-only' : 'cache-first',
          variables: {
            prefix,
            variable: attribute.name,
            type: attribute.type,
            botId: getRouteBotId(),
          },
        });

        let values = [];

        if (attribute.values) {
          values = attribute.values.slice(0);
        }

        if (data) {
          data.bot.variableValueSuggest[0].suggests.forEach(
            ({ value, id, count, type }) => {
              if (!values.find((val) => val.code === id)) {
                values.push({
                  count,
                  type,
                  name: value,
                  code: id,
                });
              }
            },
          );
        }

        values.sort((a, b) =>
          a.count < b.count ? 1 : b.count < a.count ? -1 : 0,
        );

        switch (attribute.name) {
          case 'timezone':
            values.forEach((item) => {
              const timezone = Number(item.name);
              if (!Number.isNaN(timezone)) {
                item.name = `${timezone > 0 ? '+' : ''}${timezone} GMT`;
              }
            });
            break;

          case 'locale':
            values.forEach((item) => {
              item.subtitle = PeopleService.getLanguageByLocale(item.code);
            });
            break;
          default:
        }
        return values;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getSystemVarsList() {
      return $http({
        cacheOptions: {
          need: true,
        },
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/system_vars`,
      }).then((res) => res.data.result);
    },

    /**
     *
     * @description check if you will add this domain to bot will it overflow it max allowed domain count;
     * @param bot
     * @param domain
     * @return {boolean}
     */

    isBotHaveSpaceForOneMoreDomain(bot, domain) {
      const {
        domains_whitelist: currentDomains,
        domains_whitelist_max: maximumAllowedDomains,
      } = bot;
      const copyOfDomains = uniq([...(currentDomains || []), domain]);
      return maximumAllowedDomains >= copyOfDomains.length;
    },

    getCurrentBotProStatus() {
      return this.cachedList().then((bots) => {
        const bot = bots.find((lBot) => lBot.id === getRouteBotId());
        if (bot) {
          const hasProStatus = bot.pro && bot.pro.status;
          return {
            enabled:
              hasProStatus &&
              (bot.pro.status === 'enabled' ||
                bot.pro.status === 'enabled_with_debt'),
            status: hasProStatus ? bot.pro.status : null,
          };
        }
        return null;
      });
    },

    async subscribeUsersToSequence(sequences, filter) {
      const res = await $http({
        data: {
          segmentation: filter,
          sequences,
        },
        method: 'post',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/users/sequences`,
      });
      return res.data.result;
    },

    async unsubscribeUsersFromSequence(sequences, filter) {
      const res = await $http({
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          segmentation: filter,
          sequences,
        },
        method: 'delete',
        url: `${StoreService.getApiUrl()}/bots/${getRouteBotId()}/users/sequences`,
      });
      return res.data.result;
    },

    /**
     * @return {Promise.<String>} - blank template bot id
     */
    async getBlankTemplateId() {
      const res = await $http({
        cacheOptions: {
          need: true,
        },
        method: 'get',
        url: `${StoreService.getApiUrl()}/bots/templates/blank`,
      });
      return res.data.result;
    },
  };
};
