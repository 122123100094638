export default class ModalController {
  constructor($uibModalInstance, PageService, page, analytics) {
    'ngInject';

    this.PageService = PageService;
    this.$modal = $uibModalInstance;
    this.page = page;
    this.analytics = analytics;
  }

  ok() {
    this.PageService.disconnectTPAnalytics(this.page.id, this.analytics).then(
      () => {
        this.$modal.close(true);
      },
    );
  }

  close() {
    this.$modal.close(null);
  }
}
