export const maxButtonTitleLength = 20;

export class ButtonService {
  constructor($timeout, $rootScope, CurrencyService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.CurrencyService = CurrencyService;
    this.maxButtonTitleLength = maxButtonTitleLength;
    this.targetTypes = {
      blocks: 'blocks',
      url: 'url',
      phone: 'phone-call',
      submenu: 'submenu',
      share: 'share',
      buy: 'buy',
    };

    this.targetTypesWithoutSubmenu = {
      blocks: 'blocks',
      url: 'url',
      phone: 'phone-call',
      share: 'share',
      buy: 'buy',
    };

    this.validatePatterns = {
      http: new RegExp(
        '^(https?://)?(?:(?:[A-Z0-9](?:[A-Z0-9-@:]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]([^|]|(\\S?{{[^{}]+?}}\\S?))+)$',
        'i',
      ),
      phone: new RegExp('^\\+?[0-9]+$'),
    };

    this.webViewSizes = {
      full: '100% window size',
      tall: '70% window size',
      compact: '40% window size',
    };
  }

  /**
   * typeDetect()
   * @params: item, blocksBox
   */
  typeDetect(item, blocksBox) {
    let targetType = this.targetTypes.blocks;

    if (!blocksBox) {
      blocksBox = 'block_ids';
    }

    if (item.url && item.url.length > 0) {
      targetType = this.targetTypes.url;
    } else if (item[blocksBox] && item[blocksBox].length > 0) {
      targetType = this.targetTypes.blocks;
    } else if (item.items) {
      targetType = this.targetTypes.submenu;
    } else if (item.phone_number && item.phone_number.length > 0) {
      item.phone_number = item.phone_number.replace('+', '');
      targetType = this.targetTypes.phone;
    } else if (item.show_share_screen) {
      targetType = this.targetTypes.share;
    } else if (item.payment_summary) {
      targetType = this.targetTypes.buy;
    } else {
      targetType = this.targetTypes.blocks;
    }

    return targetType;
  }

  /**
   * testError()
   * @params: item, blocksBox, titleObj, tt
   */
  testError(item, blocksBox, titleObj, tt) {
    const error = [];

    if (!item || (!tt && !titleObj)) {
      return error;
    }

    const targetType = tt || this.typeDetect(item, blocksBox);

    if (!blocksBox) {
      blocksBox = 'block_ids';
    }

    if (
      (!item.title || item.title.length === 0) &&
      targetType !== this.targetTypes.share &&
      targetType !== this.targetTypes.buy
    ) {
      error.push('title');
    }

    switch (targetType) {
      case this.targetTypes.blocks:
        if (!item[blocksBox] || item[blocksBox].length === 0) {
          error.push(this.targetTypes.blocks);
        }
        break;

      case this.targetTypes.url:
        if (!item.url || item.url.length === 0) {
          error.push(this.targetTypes.url);
        }
        break;

      case this.targetTypes.share:
        break;

      case this.targetTypes.phone:
        if (
          !item.phone_number ||
          item.phone_number.length === 0 ||
          !this.validatePatterns.phone.test(item.phone_number)
        ) {
          error.push(this.targetTypes.phone);
        }
        break;

      case this.targetTypes.buy:
        // eslint-disable-next-line no-case-declarations
        let totalCost = 0;
        item.payment_summary.price_list.forEach((lPrice) => {
          if (lPrice.label && lPrice.label.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            const cp = parseFloat(lPrice.amount);
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(cp)) {
              totalCost += Math.round(cp * 100);
            }
          }
        });

        if (
          !(
            totalCost &&
            this.CurrencyService.isValidPayLimit(
              totalCost,
              item.payment_summary.currency,
            )
          ) ||
          item.payment_summary.requested_user_info.length === 0
        ) {
          error.push(this.targetTypes.buy);
        }
        break;

      default:
    }

    return error;
  }

  /**
   * setTargetType()
   * @params: tagetType, sendEvent, currentElement, scope, item, blocksBox
   */
  setTargetType(targetType, sendEvent, currentElement, scope, item, blocksBox) {
    if (!blocksBox) {
      blocksBox = 'block_ids';
    }

    switch (targetType) {
      case this.targetTypes.blocks:
        /**/
        this.setCursorToInput(currentElement);
        /**/
        if (!item[blocksBox]) {
          item[blocksBox] = [];
        }
        if (sendEvent && item[blocksBox].length === 0) {
          this.$timeout(() => {
            scope.$broadcast('$setFocus');
          });
        }
        break;

      case this.targetTypes.phone:
        this.setCursorToInput(currentElement);
        break;

      case this.targetTypes.url:
        if (sendEvent) {
          this.$timeout(() => {
            scope.$broadcast('$setFocus');
          });
        }
        break;

      case this.targetTypes.submenu:
        if (!item.items) {
          item.items = [];
        }
        this.setTitleFocus(currentElement);
        break;

      case this.targetTypes.buy:
        if (!item.payment_summary) {
          item.payment_summary = {
            requested_user_info: [
              'shipping_address',
              'contact_name',
              'contact_phone',
              'contact_email',
            ],
            price_list: [{ amount: '', label: 'Price' }],
          };
          item.fallback_url = '';
          item.payment_blocks = [];
          item.variables = [];
        }
        break;

      case this.targetTypes.share:
        this.setTitleFocus(currentElement);
        break;

      default:
    }
  }

  /**
   * setCursorToInput()
   */
  setCursorToInput(element) {
    setTimeout(() => {
      const input = element.querySelector('.input');
      // eslint-disable-next-line no-multi-assign
      if (input && input.value.length === 0) {
        input.focus();
        // eslint-disable-next-line no-multi-assign
        input.selectionStart = input.selectionEnd = input.value.length;
      }
    }, 0);
  }

  /**
   * setTitleFocus()
   */
  setTitleFocus(element) {
    setTimeout(() => {
      const title = element.querySelector('.title');
      if (title) {
        if (title.innerText.length) {
          this.placeCaretAtEnd(title);
        } else {
          title.focus();
        }
      }
    }, 0);
  }

  /**
   * placeCaretAtEnd()
   * @params: el
   */
  placeCaretAtEnd(el) {
    if (!el) {
      return;
    }
    el.focus();
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  /**
   * onTitleKeyDown()
   * @params: event, scope, tt, maxDiscount
   */
  // eslint-disable-next-line consistent-return
  onTitleKeyDown(
    event,
    currentElement,
    scope,
    tt,
    maxDiscount,
    discount,
    item,
    blocksBox,
    titleObj,
  ) {
    let result = null;
    if (event && (event.keyCode === 13 || event.keyCode === 9)) {
      if (event.target.innerText.trim().length > 0) {
        if (!this.testError(item, blocksBox, titleObj, tt).length) {
          result = 'donePopup';
        } else {
          switch (tt) {
            case this.targetTypes.blocks:
              scope.$broadcast('$setFocus');
              break;
            case this.targetTypes.url:
              currentElement.querySelector('.art-textarea-expand').focus();
              break;
            case this.targetTypes.submenu:
              break;
            case this.targetTypes.phone:
              currentElement.querySelector('input').focus();
              break;
            default:
          }
        }
      }
      event.preventDefault();
      event.stopPropagation();
      return result;
    }

    if (discount < 1 && (event.keyCode > 46 || event.keyCode === 32)) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    if (discount < 0) {
      event.target.innerText = event.target.innerText.substr(0, maxDiscount);
      item.title = event.target.innerText;
    }

    this.$timeout(() => {
      const childes = event.target.querySelectorAll('*');
      if (childes && childes.length) {
        [].forEach.call(childes, (item) => {
          item.remove();
        });
      }
    });
  }

  /**
   * getWarringTTText()
   */
  getWarringTTText(botStatusConnected, botStatusPaymentEnabled) {
    let out = '';
    if (botStatusConnected) {
      if (botStatusPaymentEnabled) {
        out = 'To enable payments, update permissions in the payment setup';
      } else {
        out = 'To enable payments, complete the payment setup.';
      }
    } else {
      out =
        ' To enable payments, connect your Facebook Business Page and complete the payment setup.';
    }
    return out;
  }

  /**
   * save()
   * @params:
   */
  save(tmpItem, targetType, titleObj, item) {
    if (!item) {
      return;
    }

    delete tmpItem.new;

    switch (targetType) {
      case this.targetTypes.blocks:
        delete tmpItem.phone_number;
        delete tmpItem.url;
        delete tmpItem.show_share_screen;
        delete tmpItem.webview_height_ratio;
        delete tmpItem.items;
        delete tmpItem.payment_summary;
        delete tmpItem.fallback_url;
        delete tmpItem.payment_blocks;
        delete tmpItem.variables;
        break;
      case this.targetTypes.phone:
        tmpItem.phone_number = tmpItem.phone_number
          ? `+${tmpItem.phone_number.replace('+', '')}`
          : '';
        delete tmpItem.url;
        delete tmpItem.show_share_screen;
        delete tmpItem.block_id;
        delete tmpItem.block_ids;
        delete tmpItem.webview_height_ratio;
        delete tmpItem.payment_summary;
        delete tmpItem.fallback_url;
        delete tmpItem.payment_blocks;
        delete tmpItem.variables;
        delete tmpItem.attribute_update_list;
        break;
      case this.targetTypes.url:
        delete tmpItem.phone_number;
        delete tmpItem.show_share_screen;
        delete tmpItem.block_ids;
        delete tmpItem.items;
        delete tmpItem.payment_summary;
        delete tmpItem.fallback_url;
        delete tmpItem.payment_blocks;
        delete tmpItem.variables;
        break;
      case this.targetTypes.share:
        tmpItem.show_share_screen = true;

        delete tmpItem.phone_number;
        delete tmpItem.url;
        delete tmpItem.block_id;
        delete tmpItem.webview_height_ratio;
        delete tmpItem.payment_summary;
        delete tmpItem.fallback_url;
        delete tmpItem.payment_blocks;
        delete tmpItem.variables;
        delete tmpItem.attribute_update_list;
        break;
      case this.targetTypes.buy:
        delete tmpItem.phone_number;
        delete tmpItem.url;
        delete tmpItem.block_id;
        delete tmpItem.block_ids;
        delete tmpItem.webview_height_ratio;
        delete tmpItem.show_share_screen;
        tmpItem.payment_summary.price_list.forEach((price, i) => {
          if (!price.label || price.label.length === 0) {
            tmpItem.payment_summary.price_list[i] = null;
          } else if (!price.amount) {
            tmpItem.payment_summary.price_list[i].amount = '0.00';
          }
        });

        tmpItem.payment_summary.price_list =
          tmpItem.payment_summary.price_list.filter(Boolean);
        delete tmpItem.attribute_update_list;
        break;
      case this.targetTypes.submenu:
        delete tmpItem.phone_number;
        delete tmpItem.url;
        delete tmpItem.block_id;
        delete tmpItem.block_ids;
        delete tmpItem.webview_height_ratio;
        delete tmpItem.attribute_update_list;
        break;
      default:
    }

    if (titleObj) {
      tmpItem.title = titleObj.innerText.trim();
    }

    delete item.title;
    delete item.phone_number;
    delete item.url;
    delete item.block_id;
    delete item.block_ids;
    delete item.payment_summary;
    delete item.show_share_screen;
    delete item.new;
    delete item.webview_height_ratio;
    delete item.fallback_url;
    delete item.payment_blocks;
    delete item.variables;
    delete item.items;
  }

  isButtonInBroadcast() {
    return this.$rootScope.botPageTab === 'broadcast';
  }
}
