import template from './attr-selector.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: 'vm',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    value: '=',
    vars: '=',
    stat: '=',
    updateVarsMap: '&',
    setFocusToValue: '&',
    setAttrFocus: '=',
    onSelect: '&',
  },
});
