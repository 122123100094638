import ng from 'angular';

export default ng
  .module('app.common.flatStripTags', [])
  .directive('flatStripTags', () => (scope, element) => {
    element.bind('keypress input change', (event) => {
      const childs = element[0].querySelectorAll('*');

      if (childs && childs.length) {
        [].forEach.call(childs, (item) => {
          item.remove();
        });
      }
    });

    const listener = this.$scope.$on('$destroy', () => {
      element.unbind('keypress input change');
      listener();
    });
  }).name;
