export default class GroupController {
  constructor(
    $scope,
    $element,
    $interpolate,
    $rootScope,
    $timeout,
    GroupService,
    BlockService,
    ModalService,
    API_ERROR_MSG,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$interpolate = $interpolate;
    this.$error = '';
    this.GroupService = GroupService;
    this.BlockService = BlockService;
    this.ModalService = ModalService;
    this.API_ERROR_MSG = API_ERROR_MSG;

    this.$element.on('click', () => {
      document.activeElement.blur();
    });

    this.$sortable = {
      stop: (e, ui) => {
        const block = ui.item.sortable.model;

        for (let i = 0, l = this.groups.length; i < l; i++) {
          const group = this.groups[i];

          const index = group.blocks.findIndex((item) => item === block);
          if (index !== -1) {
            block.position = index;
            block.parent_group = group.id;
            this.BlockService.update(block);
            break;
          }
        }
      },
    };
    if (!this.group.builtin) {
      this.$sortable.connectWith = '.block-item .tags.not-builtin';
    }
  }

  $onDestroy() {
    this.$element.off('click');
  }

  go(id) {
    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/structure/${id}`,
    );
  }

  toEdit() {
    if (this.group.builtin) {
      return;
    }
    this.edited = true;
    this.$timeout(() => this.$element.find('input')[0].focus());
  }

  /**
   * updateGroup
   * @return undefined
   */
  updateGroup() {
    this.GroupService.update(this.group.id, {
      title: this.group.title,
      position: this.groups.findIndex((item) => item === this.group),
    })
      .then(() => {
        this.edited = false;
      })
      .catch((err) => {
        this.$error = err.data.message;
      });
  }

  /**
   * onDeleteGroup
   * @param {Object} e
   * @return undefined
   */
  onDeleteGroup(e) {
    e.preventDefault();

    if (!this.group.blocks || !this.group.blocks.length) {
      return this.deleteGroup();
    }
    let content;
    if (this.group.blocks.length >= 10) {
      content = this.$interpolate(this.ModalService.templates.groupMoreTen())({
        group: this.group.title,
      });
    } else {
      content = this.$interpolate(this.ModalService.templates.group())({
        group: this.group.title,
        blocks: (this.group.blocks || []).map((g) => g.title).join(', '),
      });
    }
    this.ModalService.confirm(content).then(
      (isConfirmed) => isConfirmed && this.deleteGroup(),
    );
    return undefined;
  }

  deleteGroup() {
    this.GroupService.remove(this.group.id)
      .then((res) => {
        const i = this.groups.findIndex((item) => item.id === this.group.id);
        if (i !== -1) {
          this.groups.splice(i, 1);
        }
      })
      .catch((err) => {
        console.error(err);
        //    alert(JSON.stringify(err.data || this.API_ERROR_MSG));
      });
  }

  addBlock() {
    this.BlockService.save({ parent_group: this.group.id }).then((block) => {
      if (!this.group.blocks) {
        this.group.blocks = [];
      }
      this.group.blocks.push(block);
      this.$rootScope.stateHistory.push(
        `/bot/${this.$rootScope.stateParams.botId}/structure/${block.id}?new=true`,
      );
    });
  }
}
