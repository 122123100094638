import './assets/less/bot-select.less';

import ng from 'angular';
import Component from './component.js';

/**
 * @ngdoc directive
 * @name botSelect
 * @restrict E
 */
export default ng
  .module('app.common.botSelect', [])
  .directive('botSelect', Component).name;
