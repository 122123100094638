import ng from 'angular';

export default ng
  .module('app.common.ngExpand', [])
  .directive('ngExpand', ($timeout) => {
    'ngInject';

    return (scope, element, attrs) => {
      const resize = () => {
        const offset = element[0].offsetHeight - element[0].clientHeight;
        element
          .css({ height: 'auto' })
          .css({ height: `${element[0].scrollHeight + offset}px` });
      };
      element.on('keyup', resize);
      scope.resizeCard = resize;
      $timeout(resize, 500);

      scope.$on('$destroy', () => {
        element.off('keyup');
      });
    };
  }).name;
