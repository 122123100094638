/**
 * ModalController
 */
export default class ModalController {
  /**
   * constructor
   * @param {Object} $scope
   * @param {Object} $document
   * @param {Object} $uibModalInstance
   * @param {Object} ModalService
   * @param {String} content
   */
  constructor(
    $scope,
    $document,
    $uibModalInstance,
    ModalService,
    content,
    buttons,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$document = $document;
    this.$modal = $uibModalInstance;
    this.ModalService = ModalService;
    this.content = content;
    this.buttons = buttons;

    this.catchEnter();
  }

  catchEnter() {
    const keyPressHandler = (event) => {
      if (event.keyCode === 13) {
        this.ok();
      }
    };
    this.$document.on('keypress', keyPressHandler);
    this.$scope.$on('$destroy', () => {
      this.$document.off('keypress', keyPressHandler);
    });
  }

  /**
   * ok
   * @return undefined
   */
  ok() {
    this.$modal.close(true);
  }

  /**
   * close
   * @return undefined
   */
  close() {
    this.$modal.close(false);
  }
}
