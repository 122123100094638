import QRCodeStyling, { Options } from 'qr-code-styling';

export interface QRCodeRendererOptions extends Options {}

export interface IQRCodeGenerator {
  getRawData(text: string): Promise<Blob>;
}

export class QRCodeGenerator implements IQRCodeGenerator {
  private readonly qrCode: QRCodeStyling;

  constructor(private readonly options?: Partial<QRCodeRendererOptions>) {
    this.qrCode = new QRCodeStyling(this.options);
  }

  public async getRawData(text: string): Promise<Blob> {
    this.qrCode.update({ data: text });
    const blob = await this.qrCode.getRawData();

    if (blob) {
      return blob;
    }

    throw new Error('there is no blob');
  }
}
