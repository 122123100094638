export default class ModalController {
  constructor($uibModalInstance) {
    'ngInject';

    this.$modal = $uibModalInstance;
  }

  ok() {
    this.$modal.close(true);
  }

  close() {
    this.$modal.close(false);
  }
}
