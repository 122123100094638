import template from './blocks-suggest.html';
import controller from './controller';

export default () => ({
  scope: {
    blocks: '=',
    blocksTitles: '=',
    excludeBlocks: '=',
    index: '=',
    autoOnShowError: '@',
    showOneBlockPopup: '<',
    onBlockCreated: '<',
  },
  template,
  controller,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
  bindToController: true,
  transclude: true,
});
