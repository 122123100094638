import template from './art-baloons-input.html';
import controller from './controller';

/**
 * @ngdoc directive
 * @name artBaloonsInput
 * @restrict E
 */
// TODO Для чего нужен параметр index, если он нигде не используется?
export default () => ({
  template,
  controllerAs: '$artBaloonsInput',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: true,
  scope: {
    ngModel: '=',
    index: '=',
    itemMaxLength: '@',
  },
});
