import { getBlocksGroupsObservable } from '../../modern-components/Aside/BlocksGroupsQueries';

export default class ArtRefSuggestController {
  constructor(StoreService, $element, base64, $rootScope) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.StoreService = StoreService;
    this.base64 = base64;
    this.$element = $element;

    this.popupAct = 0;
  }

  $onInit() {
    this.ngModel.$render = () => {
      this.ref = this.ngModel.$viewValue || '';
    };

    this.refs = [];

    this.blocksGroupsSubscription = getBlocksGroupsObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((groups) => {
      groups.forEach((group) => {
        group.blocks.forEach((block) => {
          if (
            block.referral_active &&
            block.referral &&
            block.referral.length
          ) {
            this.refs.push({
              block_title: block.title,
              ref: block.referral,
            });
          }
        });
      });
    });
    this.popupScrollBox = this.$element[0].querySelector('.popup-ref');
  }

  $onDestroy() {
    if (this.blocksGroupsSubscription) {
      this.blocksGroupsSubscription.unsubscribe();
    }
  }

  refFilter() {
    return this.ref
      ? this.refs.filter(
          (item) =>
            item.ref.toLowerCase().indexOf(this.ref.toLowerCase()) !== -1 &&
            this.ref.toLowerCase() !== item.ref.toLowerCase(),
        )
      : this.refs;
  }

  refChange() {
    this.ngModel.$setViewValue(this.ref);
    //    this.error = (this.base64.encode(this.ref).length + 4) > 250;
  }

  setVal(val) {
    this.ref = val;
    this.popupAct = 0;
    this.refChange();
  }

  onKeyDown(e) {
    if (e.keyCode === 13 && this.refFilter().length) {
      e.stopPropagation();
      e.preventDefault();

      this.ref = this.refFilter()[this.popupAct].ref;
      this.popupAct = 0;
      this.refChange();
    } else if (e.keyCode === 38) {
      if (this.popupAct > 0) {
        this.popupAct--;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      if (this.popupAct < this.refFilter().length - 1) {
        this.popupAct++;
      }
      this.setPopUpScroll();
      e.stopPropagation();
      e.preventDefault();
    }
  }

  setPopUpScroll() {
    const actItemObj = this.popupScrollBox.querySelector('.act');
    if (actItemObj) {
      if (actItemObj.offsetTop - 40 < this.popupScrollBox.scrollTop) {
        this.popupScrollBox.scrollTop = actItemObj.offsetTop - 40;
      } else if (
        actItemObj.offsetTop + actItemObj.offsetHeight + 40 >
        this.popupScrollBox.scrollTop + this.popupScrollBox.offsetHeight
      ) {
        this.popupScrollBox.scrollTop =
          actItemObj.offsetTop +
          actItemObj.offsetHeight -
          this.popupScrollBox.offsetHeight +
          40;
      }
    }
  }
}
