import 'jquery-ui';
import ng from 'angular';
import 'angular-ui-sortable';
import GroupService from '../services/GroupService';
import groupComponent from './component';

export default ng
  .module('app.common.group', ['ui.sortable'])
  .factory('GroupService', GroupService)
  .directive('group', groupComponent).name;
