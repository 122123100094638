import ng from 'angular';
import { trim } from 'ramda';

export default ng
  .module('app.common.artContenteditable', [])
  .directive('artContenteditable', () => ({
    restrict: 'A',
    require: '?ngModel',
    link: (scope, element, attrs, ngModel) => {
      if (!ngModel) {
        return;
      }

      element.bind('input', () => {
        scope.$apply(() => {
          const text = element[0].innerText;

          if (!trim(text)) {
            element.find('*').remove();
          }

          ngModel.$setViewValue(text);
          if (text === '') {
            setTimeout(() => {
              element[0].blur();
              element[0].focus();
            }, 1);
          }
        });
      });

      const oldRender = ngModel.$render;
      ngModel.$render = () => {
        if (oldRender) {
          oldRender();
        }
        element[0].innerText = ngModel.$viewValue || '';
      };
    },
  })).name;
