export default class ModalController {
  constructor(
    $uibModalInstance,
    $scope,
    $timeout,
    event,
    listItems,
    parentClass,
    left,
    top,
  ) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$scope = $scope;
    this.$timeout = $timeout;

    this.event = event;
    this.listItems = listItems || [];
    this.parentClass = parentClass;
    this.left = parseInt(left, 10) || 0;
    this.top = parseInt(top, 10) || 0;
  }

  $onInit() {
    setTimeout(() => {
      this.initDropdown();
    }, 0);
  }

  initDropdown() {
    const dropdown = document.querySelector('.popup_uni-dropdown');

    this.rePos(dropdown);
    this.initListeners();

    dropdown.classList.add('active');
  }

  initListeners() {
    this.$scope.$on('$angularClick', () => {
      this.close();
    });
  }

  callbackFunction(item) {
    this.$modal.close({ item });
  }

  close() {
    this.$modal.close(null);
  }

  rePos(dropdown) {
    const rect = this.event.currentTarget.getBoundingClientRect();

    if (dropdown) {
      window.angular.element(dropdown).css({
        left: rect.left + this.left,
        top: rect.top + this.top,
      });
    }
  }
}
