import ng from 'angular';

export default ng
  .module('app.common.autoSave', [])
  .directive('autoSave', () => (scope, element) => {
    let timeout = 0;
    element.bind('keypress input change', (event) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        element.triggerHandler('blur');
        element.triggerHandler('focus');
      }, 500);
    });

    element.bind('blur', (event) => {
      clearTimeout(timeout);
    });

    const listener = scope.$on('$destroy', () => {
      listener();
      element.unbind('keypress input change');
      element.unbind('blur');
    });
  }).name;
