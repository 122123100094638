import template from './template.html';
import controller from './controller';

export default () => ({
  template,
  controllerAs: 'component',
  controller,
  bindToController: true,
  restrict: 'E',
  replace: false,
  require: 'ngModel',
  scope: {
    model: '=',
    bots: '=',
  },
});
