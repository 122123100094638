export default class ErrorsController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.errorText = this.errors.errorText;
    this.buttonText = this.errors.buttonText;
    this.buttonUrl = this.errors.buttonUrl;
  }

  goToPage() {
    if (this.buttonUrl) {
      window.open(this.buttonUrl, '_blank');
      if (this.sendEvent === 'true') {
        this.$scope.$emit('$errorButtonClick');
      }
    }
  }
}
