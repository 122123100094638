export default class ArtCheckboxController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    if (this.inArray && this.name) {
      this.ngModel = this.inArray.indexOf(this.name) !== -1;

      this.$scope.$watch('$artCheckbox.ngModel', () => {
        const index = this.inArray.indexOf(this.name);

        if (this.ngModel && index === -1) {
          this.inArray.push(this.name);
        } else if (!this.ngModel && index !== -1) {
          this.inArray.splice(index, 1);
        }
      });
    }
  }
}
