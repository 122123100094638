export default class ModalController {
  constructor($uibModalInstance, $rootScope, BotService, PageService) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$rootScope = $rootScope;

    this.BotService = BotService;
    this.PageService = PageService;

    this.readOnlyFlag = true;

    this.loadBots();

    this.tabN = 0;

    this.fbUrl = '';
  }

  loadBots() {
    this.BotService.show().then((bots) => {
      this.bot = bots;

      this.loadAppId(this.bot.status.page);
    });
  }

  loadAppId(pageId) {
    this.PageService.getAppId(pageId).then((data) => {
      this.fbUrl = `https://developers.facebook.com/sa/apps/${data}/review-status/`;
    });
  }

  skipSubmission() {
    this.bot.status.read_only = false;
    this.bot.status.status = 'connected';
    this.updateBotStatus();
    this.$modal.close(null);
  }

  rejectedDone() {
    this.bot.status.read_only = false;
    this.bot.status.status = 'connected';
    this.updateBotStatus();
    this.$modal.close(null);
  }

  publishedDone() {
    this.bot.status.read_only = false;
    this.bot.status.status = 'published';
    this.updateBotStatus();
    this.$modal.close(null);
  }

  updateBotStatus() {
    this.BotService.update(this.bot.id, {
      timezone_offset: this.bot.timezone_offset,
      timezone_name: 'Unknown',
      title: this.bot.title,
      read_only: this.bot.status.read_only,
    });

    this.$rootScope.$broadcast('$updateBotStatus', {
      id: this.bot.id,
      status: this.bot.status.status,
      read_only: this.bot.status.read_only,
    });
  }

  onBotReadOnlyChange() {
    if (this.bot.status.read_only) {
      this.bot.status.status = 'pending';
    } else if (
      !this.bot.status.read_only &&
      this.bot.status.status === 'pending'
    ) {
      this.bot.status.status = 'published';
    }

    this.BotService.update(this.bot.id, {
      timezone_offset: this.bot.timezone_offset,
      timezone_name: 'Unknown',
      title: this.bot.title,
      read_only: this.bot.status.read_only,
    });

    this.$rootScope.$broadcast('$updateBotStatus', {
      id: this.bot.id,
      status: this.bot.status.status,
      read_only: this.bot.status.read_only,
    });

    if (!this.bot.status.read_only) {
      this.$modal.close(null);
    }
  }

  goToBots = () => {
    this.$rootScope.stateHistory.push('/bots');
  };
}
