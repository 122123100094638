export default class SuggestSequencesService {
  constructor($rootScope) {
    'ngInject';

    this.$rootScope = $rootScope;

    this.state = {
      scopeId: null,
      lineIndex: null,
    };
  }

  setActiveDropdown(scopeId, lineIndex) {
    this.state = {
      scopeId,
      lineIndex,
    };
  }

  closeActiveDropdown() {
    this.state = {
      scopeId: null,
      lineIndex: null,
    };
  }

  activeDropdown(scopeId, lineIndex) {
    return scopeId === this.state.scopeId && lineIndex === this.state.lineIndex;
  }
}
