import { path, clone, trim } from 'ramda';
import escapeStringRegexp from 'escape-string-regexp';
import {
  serverStorageItemGet,
  serverStorageItemSet,
  ServerStorageItemKeys,
} from '../../utils/ServerStorage';
import { canEdit, canView } from '../services/RoleService';
import {
  getBlocksGroupsObservable,
  getBlocksTitlesArchiveObservable,
} from '../../modern-components/Aside/BlocksGroupsQueries';
import {
  createBlock,
  isOptimisticBlock,
} from '../../modern-components/Aside/Mutations/BlockMutations';
import { escape, unescape } from '../../utils/escape';

const getCountOfOneBlockSelectorPopup = async () =>
  (await serverStorageItemGet(
    ServerStorageItemKeys.countOfOneBlockSelectorPopup,
  )) || 0;
const setCountOfOneBlockSelectorPopup = async (n) =>
  serverStorageItemSet(ServerStorageItemKeys.countOfOneBlockSelectorPopup, n);

export default class BlocksSuggestController {
  constructor(
    $scope,
    $element,
    $document,
    $timeout,
    $window,
    StoreService,
    BlockService,
    $rootScope,
  ) {
    'ngInject';

    this.$window = $window;
    this.$document = $document;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RoleService = { canEdit, canView };

    this.BlockService = BlockService;
    this.StoreService = StoreService;

    this.showError = false;
    this.focused = false;
    this.isShowOneBlockPopup = false;
    this.countOfOneBlockSelectorPopup = undefined;

    this.unescape = unescape;
  }

  $onInit() {
    this.$timeout(() => {
      this.input = this.$element[0].querySelector('.bs-input');
      this.scrollBox = this.$element[0].querySelector('.bs-content');
      this.popupScrollBox = this.$element[0].querySelector('.bs-popup ul');
    });

    if (!this.blocks) {
      this.blocks = [];
    }

    this.$scope.$on('$setFocus', () => {
      this.setFocusToInput();
      this.scrollToInput();
    });

    this.$scope.$on('$scrollToStart', () => {
      if (this.scrollBox) {
        this.scrollBox.scrollLeft = 0;
      }
    });

    this.$scope.$on('$setFocusByIndex', (event, index) => {
      if (index === this.index) {
        this.setFocusToInput();
        this.scrollToInput();
      }
    });

    this.$scope.$on('$setFocusOutsideStatus', (event, val) => {
      this.showError = !val;
    });

    this.$scope.$watch('vm.blocks', () => {
      this.prepareData();
    });

    this.loadCountOfOneBlockSelectorPopup();

    this.blockListSubscription = getBlocksTitlesArchiveObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((blocksList) => {
      this.mapIdToTitle = {};
      this.mapTitleToId = {};
      this.mapTitleToIdCurrent = {};
      blocksList.forEach((block) => {
        const titleKey = block.title.toLowerCase();
        this.mapIdToTitle[block.id] = block.title;
        this.mapTitleToId[titleKey] = block.id;
        if (!block.removed && block.reachable) {
          this.mapTitleToIdCurrent[titleKey] = block.id;
        }
      });
      this.prepareData();
    });

    this.blocksGroupsSubscription = getBlocksGroupsObservable(
      this.$rootScope.stateParams.botId,
    ).subscribe((groups) => {
      this.groups = groups;
    });
  }

  $onDestroy() {
    window.angular.element(this.$window).off('storage');

    if (this.blockListSubscription) {
      this.blockListSubscription.unsubscribe();
    }

    if (this.getGroupsTimeout) {
      clearTimeout(this.getGroupsTimeout);
    }

    if (this.blocksGroupsSubscription) {
      this.blocksGroupsSubscription.unsubscribe();
    }
  }

  async loadCountOfOneBlockSelectorPopup() {
    this.countOfOneBlockSelectorPopup = await getCountOfOneBlockSelectorPopup();
  }

  cutQueryStringForNewBlockTitle(title) {
    return title.substr(0, 60);
  }

  findParentGroupIdByBlockId(blockId) {
    const curGroup = this.groups.find(
      (group) =>
        group.blocks && group.blocks.some((block) => block.id === blockId),
    );

    return curGroup ? curGroup.id : '';
  }

  onFocusBlock($event, $index) {
    this.currentQueryTitle = null;
    this.currentQuery = null;

    this.currentIndex = $index;
    this.$timeout.cancel(this.popupHideTimout);
    this.hidePopup();
    this.$scope.$emit('$suggestFocused', { index: this.index });

    this.focused = true;
  }

  onBlurBlock($event) {
    if (this.preventBlur && $event) {
      this.preventBlur = null;
      $event.stopImmediatePropagation();
      $event.preventDefault();
      return false;
    }

    this.localBlocks[this.currentIndex] = this.blocks[this.currentIndex];
    this.updateBlocksTitles();

    this.popupHideTimout = this.$timeout(() => {
      $event.stopImmediatePropagation();
      this.hidePopup($event);
    }, 200);
    this.$scope.$emit('$suggestBlured');
    this.focused = false;
    return undefined;
  }

  onChangeBlock($event, $index) {
    if ([38, 40].indexOf($event.keyCode) !== -1) {
      return;
    }

    this.pupupUpdate($event);

    if (
      this.mapIdToTitle[this.localBlocks[$index]].toLowerCase().trim() !==
      this.currentQuery
    ) {
      this.setPopUpPosition($event.target.parentNode);
      this.showPopup($event);
    } else {
      this.hidePopup();
    }

    this.divInputClean($event.target);
  }

  onKeyDownBlock($event, $index) {
    if ([8, 9, 13, 37, 39, 46].indexOf($event.keyCode) !== -1) {
      if ($event.keyCode === 9 || ($event.keyCode === 13 && this.popupShowed)) {
        if ($index === this.blocksObjList.length - 1) {
          this.setFocusToInput($event);
        } else {
          this.placeCaretAtEnd(this.blocksObjList[$index + 1]);
        }
        $event.preventDefault();
        $event.stopImmediatePropagation();
      } else if ([8, 37].indexOf($event.keyCode) !== -1) {
        if (
          this.getCaretPosition(this.blocksObjList[$index]) === 0 &&
          $index > 0
        ) {
          this.placeCaretAtEnd(this.blocksObjList[$index - 1]);
          $event.preventDefault();
          $event.stopImmediatePropagation();
        }

        if (
          $event.keyCode === 8 &&
          this.blocksObjList[$index].innerText.trim().length === 1
        ) {
          this.preventBlur = true;
        }
      } else if (
        $event.keyCode === 46 &&
        this.blocksObjList[$index].innerText.trim().length === 1
      ) {
        this.preventBlur = true;
      } else if (
        $event.keyCode === 39 &&
        this.getCaretPosition(this.blocksObjList[$index]) ===
          this.blocksObjList[$index].innerText.length &&
        $index <= this.blocksObjList.length - 1
      ) {
        if ($index === this.blocksObjList.length - 1) {
          this.setFocusToInput($event);
        } else {
          this.blocksObjList[$index + 1].focus();
        }

        $event.preventDefault();
        $event.stopImmediatePropagation();
      }

      if (
        $event.keyCode !== 13 &&
        this.blocksObjList[$index].innerText.trim().length === 0
      ) {
        this.$timeout(() => {
          this.blocks.splice($index, 1);
          this.localBlocks.splice($index, 1);
          this.updateBlocksTitles();
        });
      }
    }

    this.onPopUpKeyEvent($event);
  }

  onKeyDownInput($event) {
    if (
      [8, 37].indexOf($event.keyCode) !== -1 &&
      this.getCaretPosition(this.input) === 0 &&
      this.blocksObjList.length > 0
    ) {
      if ($event.keyCode === 8) {
        this.removeBlock($event, this.blocksObjList.length - 1);
      } else {
        this.placeCaretAtEnd(this.blocksObjList[this.blocksObjList.length - 1]);
      }
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }

    this.onPopUpKeyEvent($event);
  }

  onPopUpKeyEvent($event) {
    if (
      this.popupShowed &&
      [13, 38, 40].indexOf($event.keyCode) !== -1 &&
      ((this.currentQueryTitle &&
        !this.mapTitleToId[this.currentQueryTitle.toLowerCase()]) ||
        this.outPopup.length)
    ) {
      const index = this.activePopupItems.findIndex(
        (item) => item.pos === this.popupItemHover,
      );

      if ($event.keyCode === 38 && index > 0) {
        this.popupItemHover = this.activePopupItems[index - 1].pos;
        this.setPopUpScroll();
      } else if (
        $event.keyCode === 40 &&
        index < this.activePopupItems.length - 1
      ) {
        this.popupItemHover = this.activePopupItems[index + 1].pos;
        this.setPopUpScroll();
      } else if ($event.keyCode === 13) {
        if (this.popupItemHover === -1) {
          this.createBlock();
        } else {
          this.setBlock($event, this.outPopup[this.popupItemHover].id);
        }
      }

      $event.preventDefault();
      $event.stopImmediatePropagation();
    }

    if ($event.keyCode === 13) {
      $event.preventDefault();
    }
  }

  onFocusInput($event) {
    this.currentQueryTitle = null;
    this.currentQuery = null;
    this.currentIndex = null;
    this.showPopup($event);

    this.setPopUpPosition($event.target);
    this.scrollToInput();

    this.$timeout.cancel(this.popupHideTimout);
    this.$rootScope.$emit('$suggestFocusedGlobal');
    this.$scope.$emit('$suggestFocused', { index: this.index });

    this.focused = true;
  }

  onChangeInput($event) {
    if ([38, 40].indexOf($event.keyCode) !== -1) {
      return;
    }

    this.pupupUpdate($event);
    this.setPopUpPosition($event.target);
    this.divInputClean($event.target);
  }

  onBlurInput($event) {
    this.popupHideTimout = this.$timeout(() => {
      $event.stopImmediatePropagation();
      this.hidePopup($event);
    }, 300);

    this.$scope.$emit('$suggestBlured', { index: this.index });

    if (this.autoOnShowError === 'true') {
      this.showError = true;
    }

    this.focused = false;
  }

  setPopUpScroll() {
    const actItemObj = this.popupScrollBox.querySelector('.act');
    if (actItemObj) {
      if (actItemObj.offsetTop - 40 < this.popupScrollBox.scrollTop) {
        this.popupScrollBox.scrollTop = actItemObj.offsetTop - 40;
      } else if (
        actItemObj.offsetTop + actItemObj.offsetHeight + 40 >
        this.popupScrollBox.scrollTop + this.popupScrollBox.offsetHeight
      ) {
        this.popupScrollBox.scrollTop =
          actItemObj.offsetTop +
          actItemObj.offsetHeight -
          this.popupScrollBox.offsetHeight +
          40;
      }
    }
  }

  updateBlocksTitles() {
    this.localBlocksTitles = this.localBlocks.map(
      (id) => this.mapIdToTitle[id],
    );
    this.blocksTitles = clone(this.localBlocksTitles);

    this.$timeout(() => {
      this.blocksObjList = this.$element[0].querySelectorAll('.block-title');
    });
  }

  prepareData() {
    this.localBlocks = [];

    if (this.blocks && this.blocks.length > 0 && this.mapIdToTitle) {
      this.blocks.forEach((blockId) => {
        if (this.mapIdToTitle[blockId]) {
          this.localBlocks.push(blockId);
        }
      });
    }

    this.updateBlocksTitles();
  }

  pupupUpdate($event) {
    this.$timeout.cancel(this.updatePopupTimeout);

    this.updatePopupTimeout = this.$timeout(() => {
      const query = $event
        ? trim($event.target.innerText.toLowerCase())
        : this.currentQuery
        ? this.currentQuery
        : '';
      const foundPatReplace = (str) =>
        query.length
          ? str.replace(
              new RegExp(`(${escapeStringRegexp(query)})`, 'ig'),
              '<span>$1</span>',
            )
          : str;
      const foundPatReplaceGroup = (str) =>
        query.length
          ? escape(str).replace(
              new RegExp(`(${escape(escapeStringRegexp(query))})`, 'ig'),
              '<span>$1</span>',
            )
          : escape(str);

      if ($event) {
        this.currentQueryTitle = $event.target.innerText.trim();
      }

      this.currentQuery = query && query.length ? query : null;

      this.outPopup = [];

      let outPopupByGroup = [];

      if (
        this.currentQueryTitle &&
        !this.mapTitleToId[this.currentQueryTitle.toLowerCase()]
      ) {
        this.activePopupItems = [{ pos: -1, id: null }];
      } else {
        this.activePopupItems = [];
      }

      let bCount = 1;

      if (this.groups) {
        this.groups.forEach((group) => {
          if (group.blocks.length === 0 || group.hidden) {
            return;
          }

          const groupFound = group.title.toLowerCase().indexOf(query) !== -1;
          let lFounds = [];
          const lFoundsOther = [];

          group.blocks.forEach((block) => {
            if (isOptimisticBlock(block)) {
              return;
            }

            const blockSubstringFound =
              block.title.toLowerCase().indexOf(query) !== -1;

            if (
              groupFound ||
              (blockSubstringFound &&
                this.localBlocks.indexOf(block.id) === -1 &&
                (!this.excludeBlocks ||
                  this.excludeBlocks.indexOf(block.id) === -1))
            ) {
              if (blockSubstringFound) {
                lFounds.push({
                  type: 'block',
                  title: foundPatReplace(block.title),
                  id: block.id,
                });
              } else {
                lFoundsOther.push({
                  type: 'block',
                  title: foundPatReplace(block.title),
                  id: block.id,
                });
              }

              this.activePopupItems.push({ pos: bCount, id: block.id });

              bCount++;
            }
          });

          if (lFounds.length > 0) {
            if (groupFound) {
              lFounds = lFounds.concat(lFoundsOther);
            }

            this.outPopup.push({
              type: 'group',
              title: foundPatReplaceGroup(group.title),
            });
            this.outPopup = this.outPopup.concat(lFounds);
            bCount++;
          } else if (lFoundsOther.length) {
            outPopupByGroup.push({
              type: 'group',
              title: foundPatReplaceGroup(group.title),
            });
            outPopupByGroup = outPopupByGroup.concat(lFoundsOther);
            bCount++;
          }
        });
      }

      this.outPopup = this.outPopup.concat(outPopupByGroup);

      this.popupItemHover = this.outPopup.length ? 1 : -1;
      this.popupItemHoverId =
        this.outPopup.length > 1 ? this.outPopup[1].id : null;
    }, 10);
  }

  showPopup($event) {
    this.pupupUpdate($event);

    if (!this.popupShowed) {
      this.$timeout(() => {
        this.popupShowed = true;
      }, 200);
    }
  }

  hidePopup() {
    if (this.popupShowed) {
      this.popupShowed = false;
    }
  }

  setBlock($event, id) {
    if (id) {
      if (!this.blocks) {
        this.blocks = [];
      }

      if (this.currentIndex !== null) {
        // eslint-disable-next-line no-multi-assign
        this.localBlocks[this.currentIndex] = this.blocks[this.currentIndex] =
          id;
        this.currentIndex = null;
      } else {
        this.localBlocks.push(id);
        this.blocks.push(id);
        this.input.innerHTML = '';
        this.currentQuery = null;
        this.currentQueryTitle = null;
      }

      this.updateBlocksTitles();
      this.$document[0].activeElement.blur();

      this.$scope.$emit('$updateBlocksInSuggest', { index: this.index });
      this.$scope.$emit('$blockAdded', { index: this.index });
      this.hidePopup();
    }

    $event.stopImmediatePropagation();
  }

  removeBlock($event, $index) {
    this.localBlocks.splice($index, 1);
    this.blocks.splice($index, 1);

    this.updateBlocksTitles();
    $event.stopPropagation();
    this.setFocusToInput();
    this.$scope.$emit('$updateBlocksInSuggest', { index: this.index });
  }

  setFocusToInput($event) {
    this.$timeout(() => {
      this.setPopUpPosition(this.input);
      if (document.activeElement !== this.input) {
        this.placeCaretAtEnd(this.input);
      }
      this.scrollToInput();
    });

    if ($event) {
      $event.stopImmediatePropagation();
    }
  }

  scrollToInput() {
    if (this.input) {
      this.scrollBox.scrollLeft =
        this.input.offsetLeft -
        this.scrollBox.offsetWidth +
        this.input.offsetWidth;
      this.setPopUpPosition(this.input);
    }
  }

  setPopUpPosition(el) {
    this.$timeout(
      () => {
        const popupLeft = el.offsetLeft - this.scrollBox.scrollLeft;
        this.popupLeft = popupLeft < 0 ? 0 : this.popupLeft;
      },
      this.popupShowed ? 10 : 200,
    );
  }

  placeCaretAtEnd(el) {
    //  el.scrollIntoView(true);
    el.focus();
    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  getCaretPosition(el) {
    let caretPos = 0;
    let sel;
    let range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === el) {
          caretPos = range.endOffset;
        }
        if (!caretPos) {
          caretPos = sel.anchorOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() === el) {
        const tempEl = document.createElement('span');
        el.insertBefore(tempEl, el.firstChild);
        const tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint('EndToEnd', range);
        caretPos = tempRange.text.length;
        el.removeChild(tempEl);
      }
    }
    return caretPos;
  }

  async createBlock() {
    const currentBlockId = this.$rootScope.stateParams.blockId;
    const currentGroup =
      currentBlockId &&
      this.groups.find((group) =>
        group.blocks.some((block) => block.id === currentBlockId),
      );
    let parentGroup;

    const inWritableGroup = (group) => {
      return group && !group.builtin && !group.sequence && !group.synced_from;
    };

    if (inWritableGroup(currentGroup)) {
      parentGroup = currentGroup;
    } else {
      parentGroup = this.groups.find((group) => inWritableGroup(group));
    }

    this.$document[0].activeElement.blur();
    const position =
      parentGroup &&
      parentGroup.blocks.findIndex((block) => block.id === currentBlockId);
    const createBlockRes = await createBlock({
      botId: this.$rootScope.stateParams.botId,
      group: parentGroup,
      title: this.cutQueryStringForNewBlockTitle(this.currentQueryTitle),
      position:
        position !== undefined && position > -1 ? position + 1 : undefined,
      silent: true,
      history: this.$rootScope.stateHistory,
    });

    const newBlock = path(['data', 'createBlock', 'block'], createBlockRes);
    if (newBlock && this.blocks) {
      const lIndex = this.currentIndex;
      if (lIndex !== null) {
        this.blocks[lIndex] = newBlock.id;
      } else {
        this.blocks.push(newBlock.id);
      }
      this.mapIdToTitle[newBlock.id] = newBlock.title;
      this.prepareData();
      this.currentQueryTitle = null;
      this.currentQuery = null;
      this.currentIndex = null;
      this.input.innerHTML = '';
      this.scrollToInput();
      this.$scope.$emit('$updateBlocksInSuggest', { index: this.index });

      if (this.onBlockCreated) {
        this.onBlockCreated();
      }
    }
  }

  divInputClean(el) {
    const childs = el.querySelectorAll('*');
    if (childs && childs.length) {
      [].forEach.call(childs, (item) => {
        item.remove();
      });
    }
  }

  go(title) {
    const lowerCaseTitle = title && title.toLowerCase();
    const id = this.mapTitleToIdCurrent[lowerCaseTitle];

    if (!id) {
      return;
    }

    this.$rootScope.stateHistory.push(
      `/bot/${this.$rootScope.stateParams.botId}/structure/${id}`,
    );
  }

  onOneBlockPopupShow() {
    if (this.isUseOneBlockPopup()) {
      this.isShowOneBlockPopup = true;
      setImmediate(() => this.incCountOfOneBlockSelectorPopup());
    }
  }

  onOneBlockSelected = (blockId) => {
    this.$scope.$evalAsync(() => {
      this.blocks.length = 0;
      this.localBlocks.length = 0;
      this.blocks[0] = blockId;
      this.localBlocks[0] = blockId;
      this.updateBlocksTitles();
    });
  };

  onOneBlockPopupCloseRequest = () => {
    this.$scope.$evalAsync(() => {
      this.isShowOneBlockPopup = false;
    });
  };

  isUseOneBlockPopup = () => {
    const LIMIT_SHOW_ONE_BLOCK_SELECTOR_POPUP = 5;
    return (
      this.showOneBlockPopup &&
      this.countOfOneBlockSelectorPopup !== undefined &&
      this.countOfOneBlockSelectorPopup < LIMIT_SHOW_ONE_BLOCK_SELECTOR_POPUP
    );
  };

  incCountOfOneBlockSelectorPopup = async () => {
    if (this.countOfOneBlockSelectorPopup !== undefined) {
      this.countOfOneBlockSelectorPopup++;
      await setCountOfOneBlockSelectorPopup(this.countOfOneBlockSelectorPopup);
    }
  };
}
