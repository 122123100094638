/**
 * @description: Popup for Pricing Calculator
 */
import { getAdminFeatures } from '@utils/Data/Admin';

export default class ModalController {
  /**
   * @description -
   * @param {*} $uibModalInstance -
   * @param {*} $scope -
   * @param {*} MonetizationService -
   * @param {boolean} userStatusPro - if user pro or not;
   * @param {*} pricingData - Pricing info (price array, next step users qty etc.)
   */
  constructor(
    $uibModalInstance,
    $scope,
    $timeout,
    MonetizationService,
    userStatusPro,
    pricingData,
  ) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.userStatusPro = userStatusPro;
    this.pricingData = pricingData;
    this.MonetizationService = MonetizationService;
    this.sliderValue = 0;
    this.loadAbtToShowSpecialFixedPriceOffer();
  }

  /**
   * @description: Loading abt flag and show block with fixed price offer;
   */
  async loadAbtToShowSpecialFixedPriceOffer() {
    try {
      const adminFeatures = await getAdminFeatures();
      this.showSpecialOffer = !!adminFeatures.fixed_price_offer_old_pricing;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description - not sure;
   * @return {void} -
   */
  $onInit() {
    if (this.userStatusPro) {
      this.showUserPricing();
    } else {
      this.sliderCost = this.proPlanFeeCalculator(this.sliderValue);
    }
    this.initSlider();
  }

  /**
   * @description slider update based on init input;
   * @return {void} -
   */
  showUserPricing() {
    if (!this.pricingData.currentUsers || this.pricingData.currentUsers < 0) {
      this.pricingData.currentUsers = 0;
    }

    this.pricingUsers = this.pricingData.currentUsers;
    this.pricingCost = this.proPlanFeeCalculator(this.pricingUsers);

    this.setNewValuesToSlider();
  }

  /**
   * @description slider update;
   * @return {void} -
   */
  setNewValuesToSlider() {
    this.sliderValue = this.pricingData.currentUsers;
    this.sliderCost = this.proPlanFeeCalculator(this.sliderValue);
  }

  /**
   * @description: TODO: I do not uderstand;
   * @param {*} value -
   * @return {*} -
   */
  proPlanFeeCalculator(value) {
    let targetIndex;
    let cost = null;

    this.pricingData.pricing.forEach((userValue, index) => {
      if (targetIndex !== undefined) {
        return;
      }

      if (userValue[0] >= value) {
        targetIndex = index;
      }
    });

    if (targetIndex === undefined) {
      // eslint-disable-next-line prefer-destructuring
      cost = this.pricingData.pricing[this.pricingData.pricing.length - 1][1];
    } else {
      // eslint-disable-next-line prefer-destructuring
      cost = this.pricingData.pricing[targetIndex][1];
    }

    return cost;
  }

  /**
   * @description: init slider;
   * @return {void} -
   */
  initSlider() {
    this.sliderPricing = {
      value: this.sliderValue,
      options: {
        floor: 0,
        ceil: this.pricingData.usersLimit,
        step: 1,
        id: 'slider-pricing',
        showSelectionBar: true,
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'floor':
              return ' ';
            case 'ceil':
              return `over ${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
            default:
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }
        },
        onChange: (id, value) => {
          this.sliderCost = this.proPlanFeeCalculator(value);
        },
        customValueToPosition: (val, minVal, maxVal) => {
          minVal = 89;
          val = Math.min(Math.max(val, minVal), maxVal);
          if (this.pricingData.exponential) {
            return (
              (Math.log(val) - Math.log(minVal)) /
              (Math.log(maxVal) - Math.log(minVal))
            );
          }
          return (val - minVal) / (maxVal - minVal);
        },
        customPositionToValue: (percent, minVal, maxVal) => {
          minVal = 89;
          percent = Math.min(Math.max(percent, 0), 1);
          if (this.pricingData.exponential) {
            return Math.exp(
              Math.log(minVal) +
                percent * (Math.log(maxVal) - Math.log(minVal)),
            );
          }
          return percent * (maxVal - minVal) + minVal;
        },
      },
    };

    this.$timeout(() => {
      this.$scope.$broadcast('rzSliderForceRender'); // rerender slider after end popup animation
    }, 200);
  }

  /**
   * @description: dead line when we show special offer and ask contacting us for more details
   * @return {boolean} - show / not show
   */
  isShowSpecialOffer() {
    return this.sliderCost >= this.pricingData.specialOfferLimit;
  }

  /**
   * @description: modal cloese itself
   * @return {void} -
   */
  close() {
    this.$modal.close(false);
  }
}
