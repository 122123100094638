export default class ModalController {
  constructor($uibModalInstance, clonedBot) {
    'ngInject';

    this.$modal = $uibModalInstance;

    this.botTitle = `${clonedBot.title} copy`;
  }

  ok() {
    this.$modal.close(this.botTitle);
  }

  close() {
    this.$modal.close(null);
  }
}
