import { stripTags } from '../../utils/stripTags';

export default class SystemAttributeController {
  constructor($scope, $timeout, $element, $compile) {
    'ngInject';

    const reg = new RegExp('{{([^{}]+?)}}', 'gi');

    this.watcher = $scope.$watch(
      '$bindAndAttrLighting.bindAndAttrLighting',
      (attr) => {
        if (attr !== undefined) {
          const obj = angular.element(
            `<div>${stripTags(attr).replace(
              reg,
              '<span system-attribute system-attribute-text="$1" system-attribute-top="true" system-attribute-no-popup="false">$1</span>',
            )}</div>`,
          );

          $element.empty();
          $element.append($compile(obj)($scope).contents());
        }
      },
    );
  }

  $onDestroy() {
    if (this.watcher) {
      this.watcher();
    }
  }
}
