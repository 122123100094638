import noop from 'lodash-es/noop';
import { filterXSS } from 'xss';
import { getAttributesQueryObservable } from '../../utils/AttributesUtils/AttributesUtils';
import { Platform } from '../../../@types/globalTypes';
import { VariableSuggestType } from '../../utils/AttributesUtils/AttributesUtilsTypes';

export default class ArtTextareaExpandController {
  constructor($element, $scope, $compile, $rootScope, PeopleService) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.$compile = $compile;
    this.$rootScope = $rootScope;

    this.PeopleService = PeopleService;

    this.showError = false;
    this.focused = false;

    $scope.$watch('$artTextareaExpand.value', () => {
      if (this.$element[0].innerText !== this.value) {
        this.$element[0].innerText = this.value ? this.value : '';
        this.updateAttrs();
      }
    });

    $scope.$on('$setFocusByIndex', (event, index) => {
      if (index === this.index) {
        this.$element[0].focus();
      }
    });

    $scope.$on('$setFocus', () => {
      this.$element[0].focus();
    });

    $scope.$on('$setFocusOutsideStatus', (event, val) => {
      this.showError = !val;
    });
  }

  $onInit() {
    this.$element.on('input', () => {
      this.$scope.$evalAsync(() => {
        this.value = this.$element[0].innerText;
      });
      this.$scope.$emit('$textareaChange');
      this.updateDiscount();

      this.updateAttrs(true);
    });

    this.$element.on('keyup', ($event) => {
      if (this.ngKeyup) {
        this.$scope.$evalAsync(() => {
          this.ngKeyup({ $event });
        });
      }
      this.render();
    });

    this.$element.on('keydown', ($event) => {
      if ([8, 9, 37, 39, 46].indexOf($event.keyCode) !== -1) {
        setTimeout(() => {
          this.updateAttrs(true, true);
        }, 1);
      } else {
        if (this.maxLength > 0 && this.discount < 1) {
          $event.preventDefault();
        }

        if (this.f_popup_show && [13, 38, 40].indexOf($event.keyCode) !== -1) {
          $event.preventDefault();
          $event.stopImmediatePropagation();
          $event.stopPropagation();

          switch ($event.keyCode) {
            case 13:
              this.addParam();
              this.hidePopup();
              return false;
            case 38:
            case 40:
              // eslint-disable-next-line no-case-declarations
              const lis = this.popup.find('li');
              this.currentNTagParam += $event.keyCode === 38 ? -1 : 1;
              if (this.currentNTagParam < 0) {
                this.currentNTagParam = 0;
              } else if (this.currentNTagParam >= lis.length) {
                this.currentNTagParam = lis.length - 1;
              }
              window.window.angular.forEach(lis, (li, i) => {
                const el = window.window.angular.element(li);
                if (i === this.currentNTagParam) {
                  el.addClass('act');
                  this.currentParam = el.text();
                } else {
                  el.removeClass('act');
                }
              });
              return undefined;
            default:
          }
        }
      }
      if (this.ngKeydown) {
        this.$scope.$evalAsync(() => {
          this.ngKeydown({ $event });
        });
      }
      return undefined;
    });

    this.$element.on('blur', () => {
      const trimmedValue = this.value.trim();
      if (!trimmedValue) {
        this.$element[0].innerText = '';
      } else if (this.value !== trimmedValue) {
        this.value = trimmedValue;
      }
      this.focused = false;
      if (!this.value && this.showError) {
        this.$element.addClass('error');
      }
      this.updateAttrs();
    });

    this.$element.on('focus', () => {
      this.focused = true;
      this.updateDiscount();
      this.$element.removeClass('error');
    });

    this.$element.on('paste', (event) => {
      const replaceInvalidCharacters = (string) => {
        const specialCharacters = ['–', '’', '<.*?>', '\\r', '\\n', '\\s+'];
        const normalCharacters = ['-', "'", '', ' ', ' ', ' '];
        let regEx;
        for (let x = 0; x < specialCharacters.length; x++) {
          regEx = new RegExp(specialCharacters[x], 'g');
          string = string.replace(regEx, normalCharacters[x]);
          if (this.maxLength) {
            string = string.slice(0, this.maxLength);
          }
        }
        return string;
      };
      event.preventDefault();
      document.execCommand(
        'inserttext',
        false,
        replaceInvalidCharacters(
          event.originalEvent.clipboardData.getData('text/plain'),
        ),
      );
      if (this.ngKeyup) {
        this.$scope.$evalAsync(() => {
          this.ngKeyup();
        });
      }
      return false;
    });

    if (this.userAttributeSuggest === 'true') {
      this.currentNTagParam = 0;
      this.currentParam = '';

      this.attributesSubscription = getAttributesQueryObservable(
        this.$rootScope.stateParams.botId,
        VariableSuggestType.template,
        Platform.facebook,
      ).subscribe((allBotAttributesForSuggest) => {
        this.botParamsCache =
          this.PeopleService.filterAndSortCustomFirstAttributes(
            allBotAttributesForSuggest,
          ).map((attr) => attr.name);
        this.updateAttrs(false, true);
      });

      this.$element.on('mouseup', () => {
        this.updateAttrs(false, true);
      });

      this.rcol = window.angular.element('.rcol');
      this.rcol.on('scroll', () => {
        this.posPopUp();
      });

      this.popup = window.angular.element(
        '<ul class="art-textarea-expand-popup"></ul>',
      );

      this.popup.on('mouseup', (event) => {
        if (event.target.tagName.toLowerCase() === 'li') {
          this.currentParam = event.target.firstChild.innerText;
          this.addParam();
          this.hidePopup();
        }
        if (event.target.tagName.toLowerCase() === 'span') {
          this.currentParam = event.target.innerText;
          this.addParam();
          this.hidePopup();
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      });

      this.popup.on('mousedown', (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      });

      this.popup.on('click', (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      });
    }

    window.angular.element('body').append(this.popup);
  }

  $onDestroy() {
    this.$element.off('keyup');
    this.$element.off('input');
    this.$element.off('keydown');
    this.$element.off('blur');
    this.$element.off('focus');
    this.$element.off('paste');
    this.$element.off('mouseup');

    if (this.popup) {
      this.popup.off('mouseup');
      this.popup.off('mousedown');
      this.popup.remove();
    }
    if (this.rcol) {
      this.rcol.off('scroll');
    }

    if (this.attributesSubscription) {
      this.attributesSubscription.unsubscribe();
    }
  }

  updateDiscount() {
    if (Number(this.maxLength) > 0) {
      this.$element.addClass('show-discount');
      this.discount = this.maxLength - this.$element[0].innerText.length;
      if (this.discount < 0) {
        this.$element[0].innerText = this.$element[0].innerText.substring(
          0,
          this.maxLength,
        );
        this.discount = 0;
      }

      this.$element.attr('discount', this.discount);

      if (this.discount < 7) {
        this.$element.addClass('show-discount-red');
      } else {
        this.$element.removeClass('show-discount-red');
      }
    } else {
      this.$element.removeClass('show-discount');
    }
  }

  saveCaretPosition(context) {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) {
      return noop;
    }
    const range = selection.getRangeAt(0);
    range.setStart(context, 0);
    const len = range.toString().length;
    return () => {
      const getTextNodeAtPosition = (root, index) => {
        const treeWalker = document.createTreeWalker(
          root,
          window.NodeFilter.SHOW_TEXT,
          (elem) => {
            if (index > elem.textContent.length) {
              index -= elem.textContent.length;
              return window.NodeFilter.FILTER_REJECT;
            }
            return window.NodeFilter.FILTER_ACCEPT;
          },
        );
        const c = treeWalker.nextNode();

        return {
          node: c || root,
          position: c ? index : 0,
        };
      };
      const pos = getTextNodeAtPosition(context, len);
      selection.removeAllRanges();
      const nRange = new window.Range();
      nRange.setStart(pos.node, pos.position);
      selection.addRange(nRange);
      return pos;
    };
  }

  updateAttrs(needRestorePos, noUpdateDoom) {
    if (this.userAttributeSuggest === 'true') {
      const reg = new RegExp('\\{\\{([^{}]+?)\\}\\}', 'gi');
      let restorePos;
      if (needRestorePos) {
        restorePos = this.saveCaretPosition(this.$element[0]);
      }
      this.revmoveTT();

      if (!noUpdateDoom) {
        this.$element[0].innerHTML = filterXSS(this.$element[0].innerText)
          .replace(
            reg,
            '<span system-attribute system-attribute-text="$1" system-attribute-top="true">{<x>{<span>$1</span>}</x>}</span>',
          )
          .replace('{{', '<b></b>{{');
        window.angular.forEach(this.$element.find('span'), (span) => {
          this.$compile(span)(this.$scope);
        });
      }

      if (needRestorePos) {
        this.preparePopup(restorePos());
      }

      this.render();
    }
  }

  render() {
    if (this.userAttributeSuggest === 'true') {
      if (this.currentElement) {
        window.angular.element(this.currentElement).removeClass('current');
        this.currentElement = null;
      }
      const { focusNode } = window.getSelection();
      if (!focusNode) {
        return;
      }
      this.currentElement = window.getSelection().focusNode.parentNode;
      if (this.currentElement) {
        let level = 0;
        while (
          level < 3 &&
          this.currentElement.parentNode &&
          this.currentElement.parentNode.contentEditable !== 'true'
        ) {
          this.currentElement = this.currentElement.parentNode;
          level++;
        }
        if (
          this.currentElement.parentNode === this.$element[0] &&
          this.getCaretPosition(this.currentElement) === 0
        ) {
          window.angular.element(this.currentElement).addClass('current');
        } else {
          this.currentElement = null;
        }
      }
    }
  }

  preparePopup(pos) {
    setTimeout(() => {
      let skPos = -1;

      if (this.currentElement) {
        skPos = 0;

        this.currentTextNode = null;
        this.currentPos = null;
      } else {
        skPos = pos.node.textContent
          .substring(0, pos.position)
          .lastIndexOf('{{');
        if (skPos !== -1) {
          skPos += 2;
        }

        this.currentTextNode = pos.node;
        this.currentPos = pos.position;
      }

      if (skPos !== -1) {
        this.query = pos.node.textContent
          .substring(skPos, pos.position)
          .replace('{', '')
          .toLowerCase();

        let out = '';
        let cou = 0;
        this.botParamsCache.forEach((item, i) => {
          if (item.toLowerCase().indexOf(this.query) !== -1) {
            if (cou === 0) {
              this.currentParam = item;
              out += `<li class="act" system-attribute system-attribute-text="${item}"><span>${item}</span></li>`;
            } else {
              out += `<li system-attribute system-attribute-text="${item}"><span>${item}</span></li>`;
            }
            cou++;
          }
        });

        this.revmoveTT();

        if (out.length) {
          this.popup.html(
            this.$compile(window.angular.element(filterXSS(out)))(this.$scope),
          );
          this.posPopUp();
          this.showPopup();
        } else {
          this.hidePopup();
        }
      } else {
        this.hidePopup();
      }
    }, 1);
  }

  posPopUp() {
    let cPos;
    if (this.currentElement) {
      cPos = this.currentElement.getBoundingClientRect();
    } else {
      const bs = this.$element[0].querySelector('b');
      if (bs) {
        cPos = bs.getBoundingClientRect();
      }
    }

    if (cPos) {
      this.popup.css({
        top: cPos.top + 10,
        left: cPos.left,
      });
    }
  }

  showPopup() {
    if (!this.f_popup_show) {
      this.f_popup_show = true;
      this.popup.css('display', 'block');
    }
  }

  hidePopup() {
    if (this.f_popup_show) {
      this.f_popup_show = false;
      this.popup.css('display', 'none');
    }
  }

  addParam() {
    if (this.currentElement) {
      this.currentElement.innerText = `{{${this.currentParam}}}`;
    } else if (this.currentTextNode) {
      if (this.query.length) {
        this.currentTextNode.textContent =
          this.currentTextNode.textContent.replace(
            this.query,
            `${this.currentParam}}}`,
          );
      } else {
        this.currentTextNode.textContent = `${
          this.currentTextNode.textContent.substr(0, this.currentPos) +
          this.currentParam
        }}}${this.currentTextNode.textContent.substr(this.currentPos)}`;
      }
    }

    const range = document.createRange();
    range.selectNodeContents(this.$element[0]);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    this.$element.triggerHandler('input');
  }

  getCaretPosition(el) {
    let caretPos = 0;
    const sel = window.getSelection();
    if (sel.rangeCount) {
      const range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode === el) {
        caretPos = range.endOffset;
      }
    }
    return caretPos;
  }

  revmoveTT() {
    [].forEach.call(document.querySelectorAll('.system-tooltip'), (tt) => {
      tt.remove();
    });
  }
}
