import { clone } from 'ramda';

export default class LocalizationController {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.loading = true;
    this.watcher = this.$scope.$watch('vm.item.localization', (data) => {
      if (data) {
        this.watcher();
        const localization = Array.isArray(data)
          ? data
          : Object.entries(data).map(([key, value]) => ({ key, value })); // todo Localization to Array in backend

        this.show = localization.length !== 0;
        this.localizationStorage = localization;
        this.loading = false;
      }
    });
  }

  save(item) {
    this.item.localization = item;
    this.PluginListController.savePlugin(this.item);
  }
}
