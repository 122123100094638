import template from './template.html';
import controller from './controller';

export default () => ({
  scope: {
    elements: '=',
    blocks: '=',
    index: '=',
  },
  template,
  controller,
  controllerAs: 'vm',
  restrict: 'E',
  bindToController: true,
  transclude: true,
});
