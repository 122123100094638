import ng from 'angular';

export default ng
  .module('app.common.artOnlyNumber', [])
  .directive('artOnlyNumber', () => (scope, element) => {
    const keyCode = [
      8,
      9,
      37,
      39,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      110,
    ];

    element.on('keydown', (event) => {
      if (keyCode.indexOf(event.keyCode) === -1) {
        event.preventDefault();
      }
    });

    scope.$on('$destroy', () => {
      element.off('keydown');
    });
  }).name;
