export default class ModalController {
  constructor($uibModalInstance, $timeout, content, $uibModal, $document) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.$document = $document;
    this.invalid = true;
    this.content = content;

    if (!this.content.bot && content.blocks) {
      this.contentBlocks = content.blocks.split(',');
    }
  }

  changeValue(inputValue) {
    this.invalid = inputValue !== 'DELETE';
  }

  ok() {
    if (!this.invalid) {
      this.$modal.close(true);
    }
  }

  close() {
    this.$modal.close(false);
  }
}
