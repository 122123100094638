import ng from 'angular';

export default ng
  .module('app.common.artCurrency', [])
  .directive('artCurrency', () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      const maxLength = 10;

      const filter = (val) =>
        val.replace(/[^0-9.]+/gi, '').replace(/^0+([0-9])/, '$1');
      const formater = (val) =>
        typeof val === 'string' && val.length > 0
          ? `$${val.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')}`
          : '';
      element.attr('maxlength', maxLength);
      ngModel.$formatters.push(formater);
      ngModel.$parsers.push(filter);
      element.on('input', (event) => {
        event.target.value = formater(filter(event.target.value));
      });
      element.on('keydown', (event) => {
        const val = event.target.value;
        if ([110, 188, 190, 191].indexOf(event.keyCode) !== -1) {
          event.preventDefault();
          if (val.length > 1 && val.indexOf('.') === -1) {
            event.target.value = `${event.target.value}.`;
            element.trigger('input');
          }
        } else if (
          [8, 9, 46].indexOf(event.keyCode) === -1 &&
          val.indexOf('.') !== -1 &&
          val.length - val.indexOf('.') > 2
        ) {
          event.preventDefault();
        }
      });
      element.on('mousedown', (event) => {
        const input = event.target;
        setTimeout(() => {
          if (input.selectionStart === input.selectionEnd) {
            input.selectionStart = input.value.length;
            input.selectionEnd = input.value.length;
          }
        }, 0);
      });
      element.on('blur', (event) => {
        element.attr('maxlength', maxLength + 3);

        let val = event.target.value.trim();
        if (val.length < 2) {
          val = '';
        } else if (val.indexOf('.') === -1) {
          val += '.00';
        } else {
          while (val.length - val.indexOf('.') < 3) {
            val += '0';
          }
        }
        element[0].value = val;
        element.trigger('change');
      });
      element.on('focus', (event) => {
        element.attr('maxlength', maxLength);
      });
      scope.$on('$destroy', () => {
        element.off('input');
        element.off('mousedown');
        element.off('keydown');
        element.off('blur');
        element.off('focus');
      });
    },
  })).name;
