import controller from './controller';

export default () => ({
  controllerAs: '$bindAndAttrLighting',
  controller,
  bindToController: true,
  restrict: 'A',
  scope: {
    bindAndAttrLighting: '=',
  },
});
