import ng from 'angular';
import $ from 'jquery';

export default ng
  .module('app.common.ngautosize', [])
  .directive('ngAutosize', () => (scope, element) => {
    const resize = () => {
      let span = $(element.find('span')[0]);
      if (!span.length) {
        span = $('<span>')
          .appendTo(element[0])
          .hide();
      }
      span.text(element.find('input').val());
      const width = parseInt(span.css('width'), 10) + 40;
      element.find('input').css({ width: `${width}px` });
    };
    element.on('keyup', resize);

    scope.$on('$destroy', () => {
      element.off('keyup');
    });
  }).name;
