import ng from 'angular';
import './assets/less/style.less';

import component from './component';
import SuggestSequencesService from './service.js';

export default ng
  .module('app.common.blocksSuggestSequences', [])
  .directive('blocksSuggestSequences', component)
  .service('SuggestSequencesService', SuggestSequencesService).name;
