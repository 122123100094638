export default class ModalController {
  constructor($uibModalInstance, $scope, useBotTimezone) {
    'ngInject';

    this.$modal = $uibModalInstance;

    this.$showSelect = false;

    this.useBotTimezone = useBotTimezone;

    $scope.$on('$angularClick', () => {
      $scope.$evalAsync(() => {
        this.$showSelect = false;
      });
    });
  }

  ok() {
    this.$modal.close({ useBotTimezone: this.useBotTimezone });
  }

  close() {
    this.$modal.close(null);
  }
}
