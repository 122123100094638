export default class ArtBigDropdownController {
  constructor($scope, $timeout) {
    'ngInject';

    this.$timeout = $timeout;
    this.$scope = $scope;

    $scope.$on('$angularMouseUp', () => {
      $timeout(() => {
        this.open = false;
      });
    });
  }

  $onInit() {
    if (!this.ngModel) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.items) {
        this.ngModel = key;
        break;
      }
    }
  }

  showPopup() {
    if (!this.open) {
      this.$timeout(() => {
        this.open = !this.locked;
        this.previousValue = this.ngModel;
      });
    }
  }

  setValue(val, $event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.ngModel = val;
    this.$scope.$emit('$popupValueChange', {
      index: this.index,
      value: val,
      cardId: this.cardId,
      previousValue: this.previousValue,
    });
    this.open = false;
    this.previousValue = '';
  }
}
