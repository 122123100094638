/**
 *  - modal when user try to cancel pro subscription
 */
export default class ModalController {
  /**
   * --
   * @param {*} UserService -
   * @param {*} $uibModalInstance -
   * @param {*} payInfo -
   */
  constructor(UserService, $uibModalInstance, payInfo) {
    'ngInject';

    this.UserService = UserService;
    this.$modal = $uibModalInstance;
    this.payInfo = payInfo;
    this.isPrePayment = payInfo.isPrePayment;
    this.loadCurrentUser();
  }

  /**
   *
   */
  async loadCurrentUser() {
    const user = await this.UserService.show();
    this.first_name = (user.name && user.name.split(' ')[0]) || '';
  }

  /**
   *
   */
  cancelProPlan() {
    this.$modal.close(true);
  }

  /**
   *
   */
  close() {
    this.$modal.close(null);
  }
}
