import noop from 'lodash-es/noop';
import sequencesSubscribe from '../../components/users/sequences-subsribe-editor/modal';
import templateUrl from './confirm.tpl';
import templateSettingsUrl from './confirm-adv/confirm.tpl';
import templateAlert from './alert/confirm.tpl';
import windowTemplateUrl from './window.tpl';
import block from './templates/block.html';
import bcBlock from './templates/bcBlock.html';
import group from './templates/group.html';
import groupMoreTen from './templates/groupMoreTen.html';
import plugin from './templates/plugin.html';
import card from './templates/card.html';
import bot from './templates/bot.html';
import controller from './modal.controller';

import subscriptionTemplateUrl from './subscription/modal.tpl';
import subscriptionController from './subscription/controller';

import cloneBotTemplateUrl from './clone-bot/modal.tpl';
import cloneBotController from './clone-bot/controller';

import cloneGroupTemplateUrl from './clone-group-to-bot/modal.tpl';
import cloneGroupController from './clone-group-to-bot/controller';
import cloneAiGroupController from './clone-group-to-bot/cloneAiGroupController';

import moveToBotTemplateUrl from './move-to-bot/modal.tpl';
import moveToBotController from './move-to-bot/controller';

import inviteTemplateUrl from './confirm-adv-invite/confirm.tpl';
import inviteController from './confirm-adv-invite/controller';

import linkTemplateUrl from './confirm-block-link/confirm.tpl';
import linkController from './confirm-block-link/controller';

import wizardPaymentsWindowTemplate from './wizard-payments/window.tpl';
import wizardPaymentsTemplateUrl from './wizard-payments/content.tpl';
import wizardPaymentsController from './wizard-payments/controller';

import imageSizeTemplateUrl from './image-size/modal.tpl';
import imageSizeController from './image-size/controller';

import imageResizeTemplateUrl from './image-resize/modal.tpl';
import imageResizeController from './image-resize/controller';

import readOnlyWindowTemplate from './read-only/window.tpl';
import readOnlyTemplate from './read-only/templatereadonly.tpl';
import readOnlyController from './read-only/controller';

import broadcastPreferenceWindowTemplate from './broadcast-preference/window.tpl';
import broadcastPreferenceTemplate from './broadcast-preference/content.tpl';
import broadcastPreferenceController from './broadcast-preference/controller';

import userPropertyWindowTemplate from './user-property/window.tpl';
import userPropertyTemplate from './user-property/content.tpl';
import userPropertyController from './user-property/controller';

import userFilterPermissionTemplate from './user-filter-permission/content.tpl';
import userFilterPermissionController from './user-filter-permission/controller';

import disconnectAnalyticsWindowTemplate from './disconnect-analytics/window.tpl';
import disconnectAnalyticsTemplate from './disconnect-analytics/content.tpl';
import disconnectAnalyticsController from './disconnect-analytics/controller';

import SelectCropTypeTemplate from './select-crop-type/content.tpl';
import SelectCropTypeController from './select-crop-type/controller';

import deleteGroupTemplate from './confirm-delete-group/confirm.tpl';
import deleteGroupController from './confirm-delete-group/controller';

import deleteBotTemplate from './confirm-delete-bot/confirm.tpl';
import deleteBotController from './confirm-delete-bot/controller';

import setupAutoreplyRuleWindowTemplate from './setup-autoreply-rule/window.tpl';
import setupAutoreplyRuleTemplate from './setup-autoreply-rule/modal.tpl';
import setupAutoreplyRuleController from './setup-autoreply-rule/controller';

import proPlanPricingTemplate from './pro-plan-pricing/content.tpl';
import proPlanPricingController from './pro-plan-pricing/controller';
import proPlanPricingWindowTemplate from './pro-plan-pricing/window.tpl';

import cancelPaymentsWindowTemplate from './cancel-payments/window.tpl';
import cancelPaymentsTemplate from './cancel-payments/modal.tpl';
import cancelPaymentsController from './cancel-payments/controller';

import disconnectProWindowTemplate from './disconnect-pro/window.tpl';
import disconnectProTemplate from './disconnect-pro/modal.tpl';
import disconnectProController from './disconnect-pro/controller';

import uniDropdownWindowTemplate from './uni-dropdown/window.tpl';
import uniDropdownTemplate from './uni-dropdown/content.tpl';
import uniDropdownController from './uni-dropdown/controller';

const SHOW_MODAL_CLASS = 'show-modal-popup';

export default class ModalService {
  /**
   *
   * @param $uibModal
   */
  constructor($uibModal, $q) {
    'ngInject';

    this.$uibModal = $uibModal;

    this.templates = {
      block,
      bcBlock,
      group,
      plugin,
      card,
      bot,
      groupMoreTen,
    };
  }

  userProperty(varName, openHelp) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        varName: () => varName,
        openHelp: () => openHelp,
      },
      controller: userPropertyController,
      templateUrl: userPropertyTemplate,
      windowTemplateUrl: userPropertyWindowTemplate,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  disconnectAnalytics(page, analytics) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        page: () => page,
        analytics: () => analytics,
      },
      controller: disconnectAnalyticsController,
      templateUrl: disconnectAnalyticsTemplate,
      windowTemplateUrl: disconnectAnalyticsWindowTemplate,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  userFilterPermission() {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      size: 'user-filter-permission',
      resolve: {
        // userFilter: () => userFilter,
        // userFilteredCount: () => userFilteredCount,
        // openHelp: () => openHelp,
        // index: () => index
      },
      controller: userFilterPermissionController,
      templateUrl: userFilterPermissionTemplate,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  broadcastPreference(useBotTimezone) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        useBotTimezone: () => useBotTimezone,
      },
      controller: broadcastPreferenceController,
      templateUrl: broadcastPreferenceTemplate,
      windowTemplateUrl: broadcastPreferenceWindowTemplate,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  subscription() {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      controller: subscriptionController,
      templateUrl: subscriptionTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  readOnly() {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => '',
      },
      controller: readOnlyController,
      templateUrl: readOnlyTemplate,
      windowTemplateUrl: readOnlyWindowTemplate,
    });

    this._bindClosingBackdrop(modal, false);
    return modal;
  }

  confirm(content) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => content,
        buttons: noop,
      },
      controller,
      templateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  alert(content) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => content,
        buttons: noop,
      },
      controller,
      templateUrl: templateAlert,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  confirmAdv(content, buttons) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => content,
        buttons: () => buttons,
      },
      controller,
      templateUrl: templateSettingsUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  confirmDeleteGroup(content) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => content,
      },
      controller: deleteGroupController,
      templateUrl: deleteGroupTemplate,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  confirmDeleteBot(content) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        content: () => content,
      },
      controller: deleteBotController,
      templateUrl: deleteBotTemplate,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  confirmAdvInvite(content, buttons) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      size: 'invite',
      resolve: {
        content: () => content,
        buttons: () => buttons,
      },
      controller: inviteController,
      templateUrl: inviteTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  confirmBlockLink(content, buttons) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      size: 'block-link',
      resolve: {
        content: () => content,
        buttons: () => buttons,
      },
      controller: linkController,
      templateUrl: linkTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  wizardPayments(content, buttons) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      size: 'payments',
      resolve: {
        content: () => content,
        buttons: () => buttons,
      },
      controller: wizardPaymentsController,
      templateUrl: wizardPaymentsTemplateUrl,
      windowTemplateUrl: wizardPaymentsWindowTemplate,
    });

    this._bindClosingBackdrop(modal, false);
    return modal.result;
  }

  cloneBot(clonedBot) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        clonedBot: () => clonedBot,
      },
      controller: cloneBotController,
      templateUrl: cloneBotTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  /**
   * @ndgoc Modal
   * Initial configuration of the modal window and start
   * @param clonedGroup
   */
  cloneGroupToAnotherBot(listOfAllBots, clonedGroup) {
    const modal = this.$uibModal.open({
      controllerAs: 'modal',
      bindToController: true,
      backdrop: 'static',
      windowClass: 'clone-bot',
      resolve: {
        clonedGroup: () => clonedGroup,
        listOfAllBots: () => listOfAllBots,
      },
      controller: cloneGroupController,
      templateUrl: cloneGroupTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  /**
   * @ndgoc Modal
   * Initial configuration of the modal window and start
   * @param clonedAiGroup
   */
  cloneAiGroupToAnotherBot(listOfAllBots, clonedGroup) {
    const modal = this.$uibModal.open({
      controllerAs: 'modal',
      bindToController: true,
      backdrop: 'static',
      windowClass: 'clone-bot',
      resolve: {
        clonedGroup: () => clonedGroup,
        listOfAllBots: () => listOfAllBots,
      },
      controller: cloneAiGroupController,
      templateUrl: cloneGroupTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  moveToBot(movedBot) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        movedBot: () => movedBot,
      },
      controller: moveToBotController,
      templateUrl: moveToBotTemplateUrl,
      windowTemplateUrl,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  imageSize() {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      controller: imageSizeController,
      templateUrl: imageSizeTemplateUrl,
    });
    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  imageResize(image, ratio) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      size: 'crop',
      resolve: {
        image: () => image,
        ratio: () => ratio,
      },
      controller: imageResizeController,
      templateUrl: imageResizeTemplateUrl,
    });
    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  selectCropType(image) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        image: () => image,
      },
      controller: SelectCropTypeController,
      templateUrl: SelectCropTypeTemplate,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  setupAutoreplyRule(data, ruleItemPosition) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      windowClass: 'setup-autoreply-rule',
      controller: setupAutoreplyRuleController,
      templateUrl: setupAutoreplyRuleTemplate,
      resolve: {
        ruleData: () => data,
        ruleItemPosition: () => ruleItemPosition,
      },
      windowTemplateUrl: setupAutoreplyRuleWindowTemplate,
    });

    this._bindClosingBackdrop(modal, modal.result);
    return modal.result;
  }

  proPlanViewPricing(userHasProStatus, pricingData) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        userStatusPro: userHasProStatus,
        pricingData,
      },
      controller: proPlanPricingController,
      templateUrl: proPlanPricingTemplate,
      windowTemplateUrl: proPlanPricingWindowTemplate,
    });

    this._bindClosingBackdrop(modal, null);

    return modal.result;
  }

  cancelPayments(payInfo) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        payInfo,
      },
      controller: cancelPaymentsController,
      templateUrl: cancelPaymentsTemplate,
      windowTemplateUrl: cancelPaymentsWindowTemplate,
    });

    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  disconnectProPage(payInfo) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        payInfo,
      },
      controller: disconnectProController,
      templateUrl: disconnectProTemplate,
      windowTemplateUrl: disconnectProWindowTemplate,
    });
    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  uniDropdown(event, listItems, parentClass, left, top) {
    const modal = this.$uibModal.open({
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        event: () => event,
        listItems: () => listItems,
        parentClass: () => parentClass,
        left: () => left,
        top: () => top,
      },
      controller: uniDropdownController,
      templateUrl: uniDropdownTemplate,
      windowTemplateUrl: uniDropdownWindowTemplate,
    });
    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  sequencesSubscribeEditorPopup(params) {
    const modal = this.$uibModal.open(sequencesSubscribe(params));
    this._bindClosingBackdrop(modal, null);
    return modal.result;
  }

  // --------------------------------------------------------

  _bindClosingBackdrop(modal, res) {
    modal.opened.then(() => {
      this.modalOpen = true;
    });

    modal.rendered.then(() => {
      const doc = window.angular.element(document);
      const body = doc.find('body');
      if (!body.hasClass(SHOW_MODAL_CLASS)) {
        doc.find('body').addClass(SHOW_MODAL_CLASS);
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      doc.off('click', handler);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      doc.on('click', handler);
    });

    modal.closed.then(() => {
      const doc = window.angular.element(document);
      if (!this.modalOpen) {
        doc.find('body').removeClass(SHOW_MODAL_CLASS);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        doc.off('click', handler);
      }
    });

    modal.result.then((res) => {
      this.modalOpen = false;
      return res;
    });

    function handler(e) {
      if (e.target.classList.contains('back')) {
        modal.close(res);
      }

      if (e.target.classList.contains('scroll-box')) {
        modal.close(res);
      }
    }
  }
}
