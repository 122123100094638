import template from './art-ref-suggest.html';
import controller from './controller';

export default {
  template: () => template,
  controllerAs: 'vm',
  controller,
  restrict: 'E',
  require: {
    ngModel: 'ngModel',
  },
  // bindings: {
  //   ngModel: '=',
  // }
};
