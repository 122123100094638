import ng from 'angular';
import moment from 'moment';

export default ng
  .module('app.common.artTimeInput', [])
  .directive('artTimeInput', () => ({
    restrict: 'A',
    require: '?ngModel',
    link: (scope, element, attrs, ngModel) => {
      if (!ngModel) {
        return;
      }

      element.on('keydown', (event) => {
        let pos = element[0].selectionStart;
        const val = element[0].value;
        if (
          event.keyCode === 8 &&
          val.substr(pos - 1, 1) === ':' &&
          pos < val.length
        ) {
          pos--;
          element[0].setSelectionRange(pos, pos);
          event.preventDefault();
        } else if (
          event.keyCode === 46 &&
          val.substr(pos, 1) === ':' &&
          pos > 0
        ) {
          pos++;
          element[0].setSelectionRange(pos, pos);
          event.preventDefault();
        }
      });

      let oldVal = '';

      element.on('input', (event) => {
        let pos = element[0].selectionStart;
        const inputVal = element[0].value;
        let newVal = inputVal.replace(/[^0-9:]+/g, '');
        const tArr = newVal.split(':');
        if (tArr.length === 2) {
          if (tArr[0].length > 2 || parseInt(tArr[0], 10) > 23) {
            newVal = oldVal;
          } else if (tArr[1].length > 2 || parseInt(tArr[1], 10) > 59) {
            newVal = oldVal;
          } else if (tArr[1].length === 1 && parseInt(tArr[1], 10) > 5) {
            newVal = oldVal;
          }
        } else if (tArr.length === 1) {
          if (tArr[0].length > 2) {
            const min = newVal.substr(2, 2);
            if (min > 5) {
              newVal = oldVal;
            } else {
              newVal = `${newVal.substr(0, 2)}:${min}`;
              pos += 2;
            }
          } else if (parseInt(tArr[0], 10) > 23) {
            const min = newVal.substr(1, 2);
            if (min > 5) {
              newVal = oldVal;
            } else {
              newVal = `0${newVal.substr(0, 1)}:${min}`;
              pos += 3;
            }
          }
        }
        if (newVal.length !== inputVal.length) {
          pos--;
        }
        oldVal = newVal;
        element[0].value = newVal;
        if (element[0] === document.activeElement) {
          element[0].setSelectionRange(pos, pos);
        }
      });

      element.on('blur', (event) => {
        const tArr = element[0].value.replace(/[^0-9:]+/g, '').split(':');
        if (tArr.length === 1) {
          if (tArr[0].length === 0) {
            tArr[0] = '00';
          }
          tArr[1] = '00';
        }
        if (tArr[0].length === 1) {
          tArr[0] = `0${tArr[0]}`;
        }
        if (tArr[1].length === 0) {
          tArr[1] = '00';
        } else if (tArr[1].length === 1) {
          tArr[1] = `0${tArr[1]}`;
        }
        element[0].value = tArr.join(':');
        element.trigger('input');
      });

      ngModel.$render = () => {
        if (ngModel.$modelValue) {
          element[0].value = moment(ngModel.$modelValue).format('HH:mm');
          scope.$modelValue = ngModel.$modelValue;
          oldVal = element[0].value;
        }
      };

      ngModel.$parsers.push((val) => {
        const tArr = /^([0-9]{1,2}):([0-9]{2})$/.exec(val);
        if (tArr) {
          const date = moment.utc(scope.$modelValue);
          return +moment(
            new Date(
              date.year(),
              date.month(),
              date.date(),
              Number(tArr[1]),
              Number(tArr[2]),
              0,
            ),
          ).format('x');
        }
        return false;
      });
    },
  })).name;
