import { FBAspectRatio } from '../../../modern-components/Plugins/ImagePlugin/ratio';

export default class ModalController {
  constructor($uibModalInstance, image) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.image = image;
  }

  ok = (ratio) => {
    this.$modal.close({
      success: true,
      isSquare: ratio === FBAspectRatio.square,
    });
  };

  close = () => {
    this.$modal.close(null);
  };
}
