export default class ArtRadioController {
  constructor($scope, $element) {
    'ngInject';

    this.$scope = $scope;
    this.label = $element.text;
  }

  $onInit() {}
}
