export default class ModalController {
  constructor(
    $uibModalInstance,
    $timeout,
    $scope,
    listOfAllBots,
    clonedGroup,
    GroupService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.clonedGroup = clonedGroup;
    this.listOfAllBots = listOfAllBots;
    this.GroupService = GroupService;
  }

  $onInit() {
    this.formModel = {
      selectedBot: {},
    };
    this.data = {};
    this.data.botsList = null;
    this.cloningProcess = undefined;
    this._assign();
  }

  _assign() {
    this.data.botsList = this.listOfAllBots;
  }

  startCloningProcess() {
    this.showCloningPreloadDetector();

    return this.GroupService.cloneToAnotherBot(
      this.clonedGroup.id,
      this.formModel.selectedBot.id,
    )
      .then((result) => {
        this.$timeout(() => this.hideCloningPreloadDetector(), 3000);
        return result;
      })
      .catch((error) => error);
  }

  showCloningPreloadDetector() {
    this.cloningProcess = true;
  }

  hideCloningPreloadDetector() {
    this.cloningProcess = false;
  }

  closeModalWithDelay() {
    this.$timeout(() => this.$modal.close(this.formModel.selectedBot), 2000);
  }

  // FIXME: may be a merge issue; need to find acceptance test for this and test
  cloneHandler() {
    this.startCloningProcess().then((result) => {
      if (result && result.updateTemplate) {
        this.$scope.$emit('$sectionClonedToCurrentBot', { result });
      }
      this.closeModalWithDelay();
    });
  }

  closeHandler() {
    this.$modal.close(null);
  }
}
