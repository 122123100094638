export default class BotSelect {
  constructor($scope, $element, $timeout) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;

    this.selectedBot = {};
  }

  $onInit() {
    this._assign();
  }

  _assign() {
    const [bot] = this.bots;
    this.model = bot;
    this.selectedBot = bot;
  }

  selectBot(bot) {
    this.model = bot;
    this.selectedBot = bot;
  }

  toggleSelectExpand(e) {
    e.stopPropagation();
    this.selectorOpened = !this.selectorOpened;
  }
}
