import template from './bot.template.html';
import controller from './controller';

export default () => {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      bot: '=',
      bots: '=',
      stats: '=',
    },
    template,
    controller,
    controllerAs: 'vm',
    bindToController: true,
  };
};
