export default class ArtMiniDropdownController {
  constructor($scope, $rootScope, $timeout, $element) {
    'ngInject';

    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$element = $element;

    this.listeners = [];

    this.open = false;

    this.listeners.push(
      $scope.$on('$angularClick', () => {
        $element.removeClass('open');
        this.open = false;
      }),
    );

    this.listeners.push(
      $rootScope.$on('$suggestFocusedGlobal', () => {
        $element.removeClass('open');
        this.open = false;
      }),
    );

    this.$element.on('click', () => {
      if (!this.open) {
        setTimeout(() => {
          this.open = true;
          $element.addClass('open');
        }, 50);
      }
    });
  }

  $onDestroy() {
    this.listeners.forEach((fn) => fn.call());
    this.$element.off('click');
  }

  setValue(val) {
    this.ngModel = val;
    this.$scope.$emit('$popupValueChange', { index: this.index, value: val });
  }
}
