import ng from 'angular';

export default class FbTestBotBtnClickController {
  constructor($scope, $element, $rootScope) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;

    this.mouseOver = false;

    this.setFocus();
    this.setListeners();

    this.link = false;
  }

  setFocus() {
    setTimeout(() => {
      window.focus();
    }, 500);
  }

  setListeners() {
    this.$element.on('mouseenter', () => {
      this.mouseOver = true;
    });

    this.$element.on('mouseleave', () => {
      this.mouseOver = false;
    });

    ng.element(window).on('blur', () => {
      if (this.mouseOver) {
        setTimeout(() => {
          this.$scope.$evalAsync(() => {
            this.link = true;
            this.setFocus();
          });
        }, 1000);
        setTimeout(() => {
          this.$rootScope.$broadcast('$fbClickEvent', true);
        }, 14000);
      }
    });

    this.listener = this.$scope.$on('$destroy', () => {
      this.$element.off('mouseenter');
      this.$element.off('mouseleave');
      ng.element(window).off('blur');
      this.listener();
    });
  }
}
