import ng from 'angular';
import Broadcast from './broadcast';
import Group from './group';
import Block from './block';
import Plugins from './plugins';
import Page from './page';
import ShowFocus from './show-focus';
import InputControl from './input-control';
import User from './user';
import Modal from './modal';
import ngKey from './ng-key';
import ngExpand from './ng-expand';
import ngAutosize from './ng-autosize';
import selectText from './select-text';
import fbIframeClick from './fb-iframe-click';
import noInputSelectText from './no-input-select-text';
import autoResize from './auto-resize';
import fbTestBotBtnClick from './fb-test-bot-btn-click';
import suggestTemplate from './suggest-template';
import suggestTemplateMultiline from './suggest-template-multiline';
import smartSave from './smart-save';
import autoSave from './auto-save';
import localization from './localization';
import flatStripTags from './flat-strip-tags';
import draggableTextFields from './draggable-text-fields';
import textareaVExpand from './textarea-v-expand';
import safePaste from './safe-paste';
import artSwitcher from './art-switcher';
import artMiniDropdown from './art-mini-dropdown';
import artTextareaExpand from './art-textarea-expand';
import artBaloonsInput from './art-baloons-input';
import artBigDropdown from './art-big-dropdown';
import blocksSuggest from './blocks-suggest';
import blocksSuggestSequences from './blocks-suggest-sequences';
import artCheckbox from './art-checkbox';
import artRadio from './art-radio';
import artOnlyNumber from './art-only-number';
import artOnlyNumberCost from './art-only-number-cost';
import artCurrency from './art-currency';
import titleSlicer from './title-slicer';
import svpVariables from './svp-variables';
import errorBuble from './error-buble';
import publicVarsSuggest from './public-vars-suggest';
import systemAttribute from './system-attribute';
import bindAndAttrLighting from './bind-and-attr-lighting';
import buttonPopupEditor from './button-popup-editor';
import botNew from './bot-new';
import botSelect from './bot-select';
import artContenteditable from './art-contenteditable';
import attrSelector from './attr-selector';
import cfAutofocus from './cf-autofocus';
import uniqueModelValue from './unique-model-value';
import 'angular-utf8-base64/angular-utf8-base64';
import artRefSuggest from './art-ref-suggest';
import artOneDomainWhitelisted from './art-one-domain-whitelisted';
import artTimeInput from './art-time-input';
import 'angular-md5';
import unbindPopup from './unbind-popup';
import { ngScrollToThis } from './scroll-to-this';
import Services from './services/index';

import { CommonValidationService } from './services';
import { ButtonService } from './button-validator/service';

import {
  swapLink,
  ordinal,
  removeTime,
  inverseSpecialChars,
  attributeDescription,
} from './filters';

export default ng
  .module('app.common', [
    Broadcast,
    Group,
    Block,
    Plugins,
    ShowFocus,
    InputControl,
    User,
    Page,
    Modal,
    ngKey,
    ngExpand,
    ngAutosize,
    selectText,
    fbIframeClick,
    noInputSelectText,
    autoResize,
    fbTestBotBtnClick,
    suggestTemplate,
    suggestTemplateMultiline,
    smartSave,
    autoSave,
    localization,
    flatStripTags,
    draggableTextFields,
    textareaVExpand,
    flatStripTags,
    artSwitcher,
    artMiniDropdown,
    artTextareaExpand,
    artBaloonsInput,
    artBigDropdown,
    blocksSuggest,
    blocksSuggestSequences,
    safePaste,
    artCheckbox,
    artRadio,
    artOnlyNumber,
    artOnlyNumberCost,
    artCurrency,
    titleSlicer,
    svpVariables,
    systemAttribute,
    errorBuble,
    publicVarsSuggest,
    bindAndAttrLighting,
    buttonPopupEditor,
    botNew,
    artContenteditable,
    botSelect,
    attrSelector,
    uniqueModelValue,
    artRefSuggest,
    artOneDomainWhitelisted,
    cfAutofocus,
    'utf8-base64',
    uniqueModelValue,
    cfAutofocus,
    artTimeInput,
    unbindPopup,
    ngScrollToThis,
    'angular-md5',
    Services,
  ])
  .service('ValidationService', CommonValidationService)
  .service('ButtonService', ButtonService)
  .filter('removeTime', removeTime)
  .filter('swapLink', swapLink)
  .filter('ordinal', ordinal)
  .filter('inverse', inverseSpecialChars)
  .filter('attributeDescription', attributeDescription).name;
