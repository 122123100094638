import ng from 'angular';
/**
 * @ngdoc directive
 * @name ngKey
 *
 * @element Input or DIV contenteditable
 * @param {expression} ngKey {@link guide/expression Expression} to evaluate upon
 * click. ({@link guide/expression#-event- Event object is available as `$event`})
 */
export default ng
  .module('app.common.ngKey', [])
  .directive('ngKey', ($parse) => {
    'ngInject';

    return {
      restrict: 'A',
      link: (scope, element, attr) => {
        const validKeyCodes = [27, 13]; // escape and enter
        const fn = $parse(attr.ngKey);

        element.bind('keydown keypress', (event) => {
          if (validKeyCodes.indexOf(event.which) !== -1) {
            event.preventDefault();
            scope.$apply(fn(scope, { $event: event }));
            setTimeout(() => {
              event.target.blur();
            }, 10);
          }
        });

        scope.$on('$destroy', () => {
          element.unbind('keydown keypress');
        });
      },
    };
  }).name;
