/**
 * TooltipConfig
 *
 * @param {Object} $uibTooltipProvider
 * @return undefined
 */
function TooltipConfig($uibTooltipProvider) {
  $uibTooltipProvider.setTriggers({
    onErrorShow: 'onErrorHide',
  });
}

TooltipConfig.$inject = ['$uibTooltipProvider'];

export default TooltipConfig;
