import './broadcast-preference/style.less';
import './user-property/style.less';
import './disconnect-analytics/style.less';
import './wizard-payments/style.less';
import './clone-group-to-bot/style.less';
import './setup-autoreply-rule/style.less';
import './cancel-payments/style.less';
import './pro-plan-pricing/style.less';
import './disconnect-pro/style.less';
import './uni-dropdown/style.less';
import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import modal from 'angular-ui-bootstrap/src/modal';
import position from 'angular-ui-bootstrap/src/position';
import ModalService from './modal.service';

export default angular
  .module('app.common.modal', [ngSanitize, modal, position, 'ngFileUpload'])
  .service('ModalService', ModalService).name;
