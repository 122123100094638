import template from './group.html';
import controller from './controller';

/**
 * @ngdoc directive
 * @name group
 * @restrict E
 *
 * @description
 * Directive description
 *
 */

export default function() {
  return {
    restrict: 'E',
    scope: {
      group: '=',
      groups: '=',
    },
    template,
    controller,
    controllerAs: 'vm',
    bindToController: true,
  };
}
