import { log } from 'cf-common/src/logger';
import { toaster, Messages } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';

export default class ModalController {
  constructor(
    $q,
    $scope,
    $uibModalInstance,
    Upload,
    image,
    ratio,
    StoreService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.StoreService = StoreService;
    this.$q = $q;
    this.$modal = $uibModalInstance;
    this.image = image;
    this.ratio = ratio;
    this.Upload = Upload;
  }

  // TODO: Delete this once migration off ImageShack is completed.
  /**
   * @param {Blob | string} image
   */
  imgUrlConvertor(image) {
    if (typeof image === 'string') {
      image = image.replace('imagizer.imageshack.com', 'img.chatfuel.com');
      image = image.replace('imagizer.imageshack.us', 'img.chatfuel.com');
    }
    return image;
  }

  save = (file) => {
    this.Upload.upload({
      url: `${this.StoreService.getApiUrl()}/imgupload`,
      data: { file },
    })
      .then((response) => {
        this.$modal.close(response.data.result);
      })
      .catch((error) => {
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
        log.error({
          error,
          msg: 'Resize image error',
          data: { label: 'automate_resize_image' },
        });
        this.close();
      });
  };

  close = () => {
    this.$modal.close(null);
  };
}
