import { Level, log } from 'cf-common/src/logger';

export default class ModalController {
  constructor($uibModalInstance, $timeout, content, buttons) {
    'ngInject';

    this.$modal = $uibModalInstance;
    this.$timeout = $timeout;
    this.content = content;
    this.buttons = buttons;
  }

  ok() {
    this.$modal.close(true);
  }

  close() {
    this.$modal.close(false);
  }

  copyLink(event) {
    event.currentTarget.previousElementSibling.select();
    try {
      document.execCommand('copy');
      this.copied = true;
      this.$timeout(() => {
        this.copied = false;
      }, 1000);
      this.clearSelection();
    } catch (error) {
      log.verbose({ error });
    }
  }

  clearSelection() {
    if (document.selection) {
      document.selection.empty();
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }
}
