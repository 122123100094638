import angular from 'angular';
import CommontController from './controller';

export default class ModalController extends CommontController {
  constructor(
    $uibModalInstance,
    $timeout,
    $scope,
    listOfAllBots,
    clonedGroup,
    GroupService,
    PluginCardService,
    BlockService,
  ) {
    'ngInject';

    super(
      $uibModalInstance,
      $timeout,
      $scope,
      listOfAllBots,
      clonedGroup,
      GroupService,
    );

    this.PluginCardService = PluginCardService;
    this.BlockService = BlockService;
  }

  startCloningProcess() {
    this.showCloningPreloadDetector();
    return this.getAiBlock(this.formModel.selectedBot.ai_block)
      .then((aiBlock) => this.addAiGroupToAnotherBot(aiBlock))
      .then((aiBlock) => this.PluginCardService.save(aiBlock.cards[0]))
      .then(() => this.$timeout(() => this.hideCloningPreloadDetector(), 3000))
      .catch((error) => error);
  }

  getAiBlock(aiBlockId) {
    return this.BlockService.show(aiBlockId);
  }

  addAiGroupToAnotherBot(aiBlock) {
    if (!angular.isArray(aiBlock.cards[0].config.groups)) {
      aiBlock.cards[0].config.groups = [];
    }
    aiBlock.cards[0].blockId = this.formModel.selectedBot.ai_block;
    aiBlock.cards[0].config.groups.push(this.clonedGroup);

    return aiBlock;
  }
}
