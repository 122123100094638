import { uniq } from 'ramda';

export default class ArtBaloonsInputController {
  constructor($scope, $element, $timeout) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;

    this.showError = false;
    this.focused = false;

    $scope.$on('$setFocusLines', () => {
      this.add();
    });
    $scope.$on('$setFocusOutsideStatus', (event, val) => {
      this.showError = !val;
    });
    $scope.$on('$angularMouseDown', () => {
      if (!this.clicked && this.focused) {
        this.ngModel = uniq(
          this.ngModel.map((item) => this.clear(item)),
        ).filter(Boolean);
        this.save();
        this.focused = false;
      }
      this.clicked = false;
    });
  }

  $onInit() {
    this.$element.on('paste', (event) => {
      const replaceInvalidCharacters = (string) => {
        const specialCharacters = ['–', '’', '<.*?>', '\\r', '\\n', '\\s+'];
        const normalCharacters = ['-', "'", '', ' ', ' ', ' '];
        let regEx;
        for (let x = 0; x < specialCharacters.length; x++) {
          regEx = new RegExp(specialCharacters[x], 'g');
          string = string.replace(regEx, normalCharacters[x]);
        }
        return string;
      };
      event.preventDefault();

      const pasteText = event.originalEvent.clipboardData.getData('text/plain');

      if (pasteText.indexOf('\n') !== -1) {
        if (!this.ngModel) {
          this.ngModel = [];
        }

        this.$scope.$evalAsync(() => {
          this.ngModel.pop();
          pasteText.split('\n').forEach((val) => {
            const cVal = replaceInvalidCharacters(val).trim();
            if (cVal.length) {
              this.ngModel.push(cVal);
            }
          });
          this.save();
        });

        this.$timeout(() => {
          this.add();
        }, 50);
      } else {
        document.execCommand(
          'inserttext',
          false,
          replaceInvalidCharacters(pasteText),
        );
      }

      return false;
    });
  }

  $onDestroy() {
    this.$element.off('paste');
    // TODO Необходимо ngModel удалять
  }

  tfClick($event) {
    //    $event.stopPropagation();
    this.clicked = true;
    this.focused = true;
    this.add();
  }

  setLastFocus() {
    this.$timeout(() => {
      const lineNodes = this.$element[0].querySelectorAll('.line');
      if (lineNodes.length) {
        this.placeCaretAtEnd(lineNodes[lineNodes.length - 1]);
      }
    });
  }

  greenKeyDown($event, $index) {
    let removePrev = false;

    if ([8, 9, 13, 37, 39, 46].indexOf($event.keyCode) !== -1) {
      const lineNodes = [...this.$element[0].querySelectorAll('.line')];
      const notEmptyLines = lineNodes.filter((el) => {
        return el.innerText.length !== 0;
      });

      if ($event.keyCode === 13 || $event.keyCode === 9) {
        if ($index === this.ngModel.length - 1) {
          if (lineNodes[$index].innerText.trim().length === 0) {
            if ($index > 0) {
              this.$scope.$emit('$onLineNextTab');
            }
          } else {
            this.add();
          }
        } else {
          this.placeCaretAtEnd(lineNodes[$index + 1]);
        }

        /* allow tabulation in empty art baloons inputs */
        if ($event.keyCode === 9 && notEmptyLines.length) {
          $event.preventDefault();
        }

        $event.stopPropagation();
        $event.stopImmediatePropagation();
        this.save();
      } else if ($event.keyCode === 8) {
        if (this.ngModel[$index].length === 1) {
          this.preventBlur = true;
        }

        if (this.getCaretPosition(lineNodes[$index]) === 0 && $index > 0) {
          // this.placeCaretAtEnd(lineNodes[$index - 1]);
          $event.preventDefault();
          $event.stopPropagation();
          $event.stopImmediatePropagation();
          removePrev = true;
        }
      } else if ($event.keyCode === 46) {
        if (this.ngModel[$index].length === 1) {
          this.preventBlur = true;
        }
      } else if (
        $event.keyCode === 37 &&
        this.getCaretPosition(lineNodes[$index]) === 0 &&
        $index > 0
      ) {
        this.placeCaretAtEnd(lineNodes[$index - 1]);
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
      } else if (
        $event.keyCode === 39 &&
        this.getCaretPosition(lineNodes[$index]) ===
          lineNodes[$index].innerText.length &&
        $index < lineNodes.length - 1
      ) {
        lineNodes[$index + 1].focus();
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
      }

      if (
        this.clear(lineNodes[$index].innerText).length === 0 &&
        this.ngModel.length > 1
      ) {
        this.ngModel.splice($index, 1);

        if (removePrev && $index > 0) {
          this.ngModel[$index - 1] = '';

          this.$timeout(() => {
            this.placeCaretAtEnd(lineNodes[$index - 1]);
          });
        }

        this.save();
      }
    } else if (this.itemMaxLength > 0) {
      if (this.discount < 1 && $event) {
        $event.preventDefault();
      }
    }
  }

  greenFocus($event) {
    //  this.focused = true;
    this.$timeout.cancel(this.blurTimeout);

    this.updateDiscount($event);
  }

  greenBlur($event, $index) {
    if (this.preventBlur) {
      this.preventBlur = false;
      return;
    }
    $event.target.innerHTML = this.clear($event.target.innerHTML);

    this.blurTimeout = this.$timeout(() => {
      this.ngModel = uniq(this.ngModel).filter(Boolean);
      this.save();
    }, 100);
  }

  add() {
    if (!this.ngModel) {
      this.ngModel = [];
    }

    this.ngModel = uniq(this.ngModel).filter(Boolean);

    this.ngModel.push('');
    this.setLastFocus();
  }

  removeLine($event, index) {
    $event.stopPropagation();
    $event.preventDefault();

    this.ngModel[index] = null;
    this.ngModel = uniq(this.ngModel).filter(Boolean);

    this.save();
  }

  placeCaretAtEnd(el) {
    el.focus();
    this.caretPositionProgSetted = true;

    if (
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined'
    ) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  getCaretPosition(el) {
    let caretPos = 0;
    let sel;

    let range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === el) {
          caretPos = range.endOffset;
        }
        if (!caretPos) {
          caretPos = sel.anchorOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() === el) {
        const tempEl = document.createElement('span');
        el.insertBefore(tempEl, el.firstChild);
        const tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint('EndToEnd', range);
        caretPos = tempRange.text.length;
        el.removeChild(tempEl);
      }
    }
    return caretPos;
  }

  updateDiscount($event) {
    if (Number(this.itemMaxLength) > 0) {
      this.discount = this.itemMaxLength - $event.target.innerText.length;
      if (this.discount < 0) {
        $event.target.innerText = $event.target.innerText.substring(
          0,
          this.itemMaxLength,
        );
        this.discount = 0;
      }
    }
  }

  clear(s) {
    return s
      .replace(/( |&nbsp;)+/g, ' ')
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&amp;/gi, '&')
      .trim();
  }

  save() {
    this.$timeout.cancel(this.saveTimeout);
    this.saveTimeout = this.$timeout(() => {
      this.$scope.$emit('$linesChange');
    }, 500);
  }
}
