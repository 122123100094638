import template from './template.html';
import controller from './controller.js';

export const Component = () => ({
  controller,
  template,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
  bindToController: true,
  transclude: true,
  scope: {
    errors: '=',
    sendEvent: '@',
  },
});
